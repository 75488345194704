/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Args {
  selectedTab: string;
}

export default class PeopleHeaderComponent extends Component<Args> {
  @service declare intl: IntlService;
  @service declare modalService: any;
  @service declare appService: any;

  get titleToken() {
    return this.intl.t('new-settings.data.people.page-title');
  }

  get showLearnMenu() {
    return ['attributes', 'segments', 'events'].includes(this.args.selectedTab);
  }

  @action
  openAttributeCreationModal(type: string) {
    this.modalService.openModal('settings/modals/attribute-details', {
      attribute: null,
      allowArchival: false,
      showPeopleAndCompanyToggleTab: false,
      typeToCreate: type,
    });
  }

  @action
  showCustomAttributesArticle() {
    window.Intercom('showArticle', 179); // https://www.intercom.com/help/en/articles/179-send-custom-user-attributes-to-intercom#how-do-i-do-it
  }

  @action
  showSegmentsArticle() {
    window.Intercom('showArticle', 324);
  }

  @action
  showEventsArticle() {
    window.Intercom('showArticle', 216);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::People::Header': typeof PeopleHeaderComponent;
  }
}
