/* import __COLOCATED_TEMPLATE__ from './fin-workflows-overview.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { statisticKeys } from 'embercom/models/data/outbound/constants';
import { action } from '@ember/object';
import { Column, ColumnConfigs } from 'embercom/objects/operator/configuration/custom-bot';
import { tracked } from 'tracked-built-ins';
import type RouterService from '@ember/routing/router-service';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import type IntlService from 'embercom/services/intl';

interface Arguments {
  isLoading: boolean;
  pageSize: number;
  finWorkflowsLastPageHit: boolean;
  workflows: ContentWrapper[];
  loadMoreFinWorkflows: () => void;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Arguments;
}

enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

type TableSortState = {
  valuePath: string;
  direction: SortDirection;
};

export default class FinWorkflowsOverview extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare router: RouterService;
  @tracked sortBy: TableSortState = { valuePath: '', direction: SortDirection.ASC };

  stateKey = 'state';

  readonly columnConfigs = new ColumnConfigs();
  statisticColumns = [{ valuePath: 'sentCount', key: statisticKeys.receipts }];

  get columnKeys(): (keyof ColumnConfigs)[] {
    return ['title', this.stateKey as keyof ColumnConfigs, 'lastUpdatedBy', 'sentCount'];
  }

  get columns() {
    return this.columnKeys.map((key) => {
      let col = new Column(this.intl, this.columnConfigs[key]);
      if (key === this.stateKey) {
        col.isSortable = true;
      }
      return col;
    });
  }

  get sortedData() {
    let key = this.sortBy.valuePath;

    return this.args.workflows.sort((a: any, b: any) => {
      let aVal = a[key] || a.contents?.firstObject?.[key];
      let bVal = b[key] || b.contents?.firstObject?.[key];

      if (aVal > bVal) {
        return this.sortDirection === SortDirection.ASC ? -1 : 1;
      } else if (aVal < bVal) {
        return this.sortDirection === SortDirection.ASC ? 1 : -1;
      }

      return 0; // return 0 when aVal and bVal are equal
    });
  }

  get sortDirection() {
    return this.sortBy.direction;
  }

  @action
  onSortUpdate(sortBy: string) {
    let sort: TableSortState = {
      valuePath: sortBy,
      direction:
        this.sortBy.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
    };
    this.sortBy = sort;
  }

  @action
  async openWorkflowInNewTab(rulesetId: string) {
    safeWindowOpen(
      this.router.urlFor('apps.app.automation.workflows.edit', rulesetId, {
        queryParams: {
          mode: 'edit',
          visual: 'enabled',
        },
      }),
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::FinWorkflowsOverview': typeof FinWorkflowsOverview;
  }
}
