/* import __COLOCATED_TEMPLATE__ from './setup-domain-manually.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import { type CtaType } from 'embercom/components/new-settings/channels/email/connect-email/step';
import { type Domain, type DomainEmailAddress } from 'embercom/services/domain-service';

interface Args {
  emailAddress: DomainEmailAddress | undefined;
  changeStep: (step: string | null) => void;
  selectedDomain: Domain | null;
}

interface Signature {
  Args: Args;
}

interface SetupDomainStep {
  name: string;
  title: string;
  description: string;
  stepCompleted: boolean;
  ctaType?: CtaType;
  ctaLabel?: string;
  ctaLink: string;
}

export default class SetupDomainManually extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare domainService: any;

  @tracked isVerifying = false;

  get setupDomainSteps(): SetupDomainStep[] {
    return [
      {
        name: 'find-your-domain-records',
        title: this.intl.t(
          'new-settings.channels.email.connect-email.email-setup.setup-domain-manually-step.find-your-domain-records.title',
        ),
        description: this.intl.t(
          'new-settings.channels.email.connect-email.email-setup.setup-domain-manually-step.find-your-domain-records.description',
        ),
        stepCompleted: false,
        ctaType: 'secondary',
        ctaLabel: this.intl.t(
          'new-settings.channels.email.connect-email.email-setup.setup-domain-manually-step.find-your-domain-records.button-label',
        ),
        ctaLink:
          'https://www.intercom.com/help/en/articles/182-sending-email-from-your-own-address#verify-your-domain',
      },
      {
        name: 'copy-intercom-domain-records',
        title: this.intl.t(
          'new-settings.channels.email.connect-email.email-setup.setup-domain-manually-step.copy-intercom-domain-records.title',
        ),
        description: this.intl.t(
          'new-settings.channels.email.connect-email.email-setup.setup-domain-manually-step.copy-intercom-domain-records.description',
        ),
        stepCompleted: false,
        ctaType: undefined,
        ctaLabel: undefined,
        ctaLink: '',
      },
    ];
  }

  @action
  async verifyDomain() {
    if (!this.args.selectedDomain) {
      return;
    }
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'check_domain_authentication',
      place: 'setup-domain-manually',
      context: 'email-setup-sidebar',
    });
    try {
      this.isVerifying = true;
      await this.args.selectedDomain.dkimSettings.verify();
      await this.domainService.load();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'success',
        object: 'check_domain_authentication',
        place: 'setup-domain-manually',
        context: 'email-setup-sidebar',
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'new-settings.channels.email.addresses.domain-sidebar.domain-verification-success',
        ),
      );
      if (this.args.emailAddress) {
        this.args.changeStep('overview');
      } else {
        this.args.changeStep(null);
      }
    } catch (error) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'failed',
        object: 'check_domain_authentication',
        place: 'setup-domain-manually',
        context: 'email-setup-sidebar',
      });
      this.notificationsService.notifyError(
        this.intl.t(
          'new-settings.channels.email.addresses.domain-sidebar.domain-verification-failed',
        ),
      );
    } finally {
      this.isVerifying = false;
    }
  }

  @action
  copyTrackEvent() {
    this.trackEvent({ action: 'copied', object: 'intercom_domain_records' });
  }

  trackEvent({ action, object }: { action: string; object: string }) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      place: 'setup-domain-manually-step',
      context: 'email-setup-sidebar',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::ConnectEmail::SetupDomainManually': typeof SetupDomainManually;
    'new-settings/channels/email/connect-email/setup-domain-manually': typeof SetupDomainManually;
  }
}
