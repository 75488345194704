/* RESPONSIBLE TEAM: team-data-interop */

export const APP_TEMPLATES = [
  {
    appName: 'outbound.new-content-modal.apps.github',
    appPackageCode: 'github',
    iconUrl:
      'https://static.intercomassets.com/developer-home/change-request-image-uploads/12670/original/github-logo-1697104453.png?1697104453',
  },
  {
    appName: 'outbound.new-content-modal.apps.jira',
    appPackageCode: 'jira-for-tickets',
    iconUrl:
      'https://static.intercomassets.com/developer-home/change-request-image-uploads/11759/original/239944700-b08d3069-c5cd-44c5-b5dd-c88a8459b36b-1684767664.png?1684767664',
  },
];

export const APP_TEMPLATES_WITH_WORKFLOWS_SETUP = [
  {
    appName: 'outbound.new-content-modal.apps.github',
    appPackageCode: 'github',
    iconUrl: 'workflows/github-logo',
  },
  {
    appName: 'outbound.new-content-modal.apps.jira',
    appPackageCode: 'jira-for-tickets',
    iconUrl: 'workflows/jira-logo',
  },
];
