/* import __COLOCATED_TEMPLATE__ from './relevant-contents.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */

import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import FinAnswersApi, { type RelevantContent } from 'embercom/lib/content-service/fin-answers-api';
import type Conversation from 'embercom/objects/inbox/conversation';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type Session from 'embercom/services/session';

interface Args {
  part: RenderablePart;
  conversation: Conversation;
  isStandalone?: boolean;
}

export default class RelevantContents extends Component<Args> {
  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.getRelevantContents).perform();
  }

  @service declare intercomEventService: any;
  @service declare appService: any;
  @service declare session: Session;

  @tracked relevantContents?: [RelevantContent];
  @tracked relevantContentLoadSize = 3;
  loadSizeIncrement = 3;

  @task *getRelevantContents(): TaskGenerator<void> {
    if (!this.args.part || !this.args.conversation || !this.args.part.renderableData.issueSummary) {
      return;
    }

    let relevantContents = yield FinAnswersApi.getRelevantContents({
      part: this.args.part,
      conversation: this.args.conversation,
      appId: this.appService.app ? this.appService.app.id : this.session.workspace.id,
    });

    if (relevantContents) {
      this.relevantContents = relevantContents;
    }
  }

  get isLoading(): boolean {
    return taskFor(this.getRelevantContents).isRunning;
  }

  get relevantContentForLoadSize(): RelevantContent[] {
    return this.relevantContents
      ? this.relevantContents.slice(0, this.relevantContentLoadSize)
      : [];
  }

  get relevantContentSize(): number {
    return this.relevantContents ? this.relevantContents.length : 0;
  }

  get showLoadMoreButton(): boolean {
    return this.relevantContentLoadSize < this.relevantContentSize;
  }

  @action
  increaseRelevantContentLoadSize() {
    this.relevantContentLoadSize =
      this.relevantContentLoadSize + this.loadSizeIncrement < this.relevantContentSize
        ? this.relevantContentLoadSize + this.loadSizeIncrement
        : this.relevantContentSize;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::AnswerDebugger::RelevantContents': typeof RelevantContents;
  }
}
