/* import __COLOCATED_TEMPLATE__ from './upload-profile-picture.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type WhatsappIntegration from 'embercom/models/whatsapp/integration';
import { ref } from 'ember-ref-bucket';
import { dropTask } from 'ember-concurrency-decorators';
import type { TaskGenerator } from 'ember-concurrency';

interface UploadProfilePictureArgs {
  whatsappIntegration: WhatsappIntegration;
}

interface FileReaderEvent extends ProgressEvent<FileReader> {
  target: FileReader;
}

export default class UploadProfilePicture extends Component<UploadProfilePictureArgs> {
  @service declare intl: IntlService;
  @service declare intercomEventService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;

  @tracked isDragging = false;
  @tracked file?: File;
  @tracked uploadDidFail = false;
  @tracked uploadedImagePreview?: string;

  @ref('fileInputElement') fileInputElement!: HTMLInputElement;

  readonly maxFileSize = 63000; // 63KB in bytes

  get whatsappIntegration() {
    return this.args.whatsappIntegration;
  }

  get hasFile() {
    return !!this.file;
  }

  get fileIsTooLarge() {
    return this.file && this.file.size > this.maxFileSize;
  }

  get fileHasValidExtension() {
    let fileName = this.file?.name;
    let validExtensionPattern = /\.(png|gif|jpeg|jpg)$/i;
    return fileName ? validExtensionPattern.test(fileName) : false;
  }

  get fileIsInvalid() {
    return (
      !this.existingPictureUrl &&
      this.hasFile &&
      (!this.fileHasValidExtension || this.uploadDidFail || this.fileIsTooLarge)
    );
  }

  get message() {
    if (this.fileIsTooLarge) {
      return this.intl.t('components.mixins.image-upload-component.large-size', {
        maxFileSize: '63KB',
      });
    } else if (this.uploadDidFail) {
      return this.intl.t('components.mixins.image-upload-component.error-occurred');
    } else if (!this.fileHasValidExtension) {
      return this.intl.t('components.mixins.image-upload-component.valid-file');
    }
    return '';
  }

  isValidImageUrl(url: string): boolean {
    try {
      return !!new URL(url);
    } catch {
      return false;
    }
  }

  get existingPictureUrl() {
    return this.isValidImageUrl(this.whatsappIntegration?.profilePictureUrl)
      ? this.whatsappIntegration?.profilePictureUrl
      : null;
  }

  get previewImageUrl() {
    return this.uploadedImagePreview || this.existingPictureUrl || undefined;
  }

  @dropTask
  *uploadPictureTask(): TaskGenerator<void> {
    try {
      if (this.hasFile && !this.fileIsInvalid) {
        yield this.whatsappIntegration?.uploadProfilePicture({
          app: this.appService.app,
          image: this.file,
        });
        this.notificationsService.notifyConfirmation(
          this.intl.t(
            'new-settings.channels.whatsapp.set-business-profile-sidebar.upload-profile-picture.notification.success',
          ),
        );
        this.removeFile();
        this.intercomEventService.trackAnalyticsEvent({
          action: 'picture_uploaded',
          object: 'whatsapp_integration',
        });
      }
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t(
          'new-settings.channels.whatsapp.set-business-profile-sidebar.upload-profile-picture.notification.error',
        ),
      );
      this.uploadDidFail = true;
      this.intercomEventService.trackAnalyticsEvent({
        action: 'picture_upload_failed',
        object: 'whatsapp_integration',
      });
    }
  }

  @action
  handleDragEnter(e: DragEvent) {
    e.preventDefault();
    this.isDragging = true;
  }

  @action
  handleDragLeave(e: DragEvent) {
    e.preventDefault();
    this.isDragging = false;
  }

  @action
  handleDrop(e: DragEvent) {
    e.preventDefault();
    let file = e.dataTransfer?.files[0];
    if (file) {
      this.setFile(file);
    }
  }

  @action
  handleFileInput(e: Event) {
    let target = e.target as HTMLInputElement;
    let file = target.files?.[0];
    if (file) {
      this.setFile(file);
    }
  }

  @action
  showFilePicker() {
    this.fileInputElement.click();
  }

  @action
  removeFile() {
    this.file = undefined;
    this.uploadedImagePreview = undefined;
  }

  private setFile(file: File) {
    this.isDragging = false;
    this.file = file;
    this.uploadDidFail = false;
    this.uploadedImagePreview = undefined;

    let reader = new FileReader();
    reader.onload = (e: FileReaderEvent) => {
      this.uploadedImagePreview = e.target.result as string;
    };
    reader.readAsDataURL(file);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Whatsapp::Sidebar::UploadProfilePicture': typeof UploadProfilePicture;
    'new-settings/channels/whatsapp/sidebar/upload-profile-picture': typeof UploadProfilePicture;
  }
}
