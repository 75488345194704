/* import __COLOCATED_TEMPLATE__ from './config-import.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type Owner from '@ember/owner';
import { type MappingVisibility } from 'embercom/models/crm/attribute-mapping';
import type ImportConfigurationService from 'embercom/services/data/import-configuration-service';
import type Router from '@ember/routing/router-service';
import {
  type ImportZendeskContext,
  type MigrationStatusEnum,
} from 'embercom/lib/apps/app/new-settings/data/imports-exports/import-zendesk/zendesk-context-loader';
import { dropTask, keepLatestTask, restartableTask } from 'ember-concurrency-decorators';
import { type TaskGenerator, timeout } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { type TabName } from 'embercom/components/new-settings/data/imports-exports/import-zendesk/config-import/sections/ticket';
import ENV from 'embercom/config/environment';
import { get } from 'embercom/lib/ajax';
import { isPresent } from '@ember/utils';
import type Session from 'embercom/services/session';

export interface Args {
  integrationCode: string;
  context: ImportZendeskContext;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export class SectionConfig {
  @tracked active: boolean;
  frozen = false;
  name: SectionName;
  requires: SectionName[];
  requiredBy: SectionName[];

  constructor(
    name: SectionName,
    requires: SectionName[] = [],
    requiredBy: SectionName[] = [],
    active = false,
    frozen = false,
  ) {
    this.name = name;
    this.active = active;
    this.frozen = frozen;
    this.requires = requires;
    this.requiredBy = requiredBy;
  }
}

export type Sections = {
  company: SectionConfig;
  contact: SectionConfig;
  ticket: SectionConfig;
};

export type SectionName = keyof Sections;

export default class ConfigImport extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare session: Session;
  @service declare intercomEventService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare router: Router;
  @service('data/import-configuration-service')
  declare importConfigurationService: ImportConfigurationService;

  @tracked isSaving = false;
  @tracked migrationStarting = false;
  @tracked showStartImportDialog = false;
  @tracked showImportAgainDialog = false;
  @tracked migrationStatus: MigrationStatusEnum = 'none';

  sections: Sections = {
    company: new SectionConfig('company', [], ['contact', 'ticket']),
    contact: new SectionConfig('contact', ['company'], ['ticket']),
    ticket: new SectionConfig('ticket', ['company', 'contact']),
  };

  sectionNames: SectionName[] = ['company', 'contact', 'ticket'];

  migrationGuideHelpLink =
    'https://www.intercom.com/help/en/articles/9307237-migrate-your-zendesk-ticket-user-and-organization-data';
  faqHelpLink =
    'https://www.intercom.com/help/en/articles/9307237-migrate-your-zendesk-ticket-user-and-organization-data#h_004c33c212';

  importDialogWarnings = [
    { key: 'snapshot', icon: 'calendar' },
    { key: 'reimport', icon: 'undo' },
    { key: 'time', icon: 'clock' },
  ] as const;

  constructor(owner: Owner, args: Args) {
    super(owner, args);

    this.migrationStatus = this.args.context.migrationStatus;

    let hasMappings = (name: string, visibility: MappingVisibility) =>
      this.importConfigurationService
        .listMappings(name, visibility)
        .some(
          (mapping) =>
            mapping.editable &&
            mapping.status === 'done' &&
            mapping.destinationObjectType !== 'ticket_type._default_',
        );

    for (let name of this.sectionNames) {
      let section = this.sections[name];
      let active = section.frozen || hasMappings(name, 'public') || hasMappings(name, 'private');
      this.setSectionActive(name, active);
      if (active) {
        this.setSectionFreeze(name);
      }
    }
  }

  get hasCompletedOrCancelledImports() {
    let completedStates = ['completed', 'completed_with_errors', 'cancelled'];
    return completedStates.includes(this.args.context.migrationStatus);
  }

  get configType() {
    return this.hasCompletedOrCancelledImports ? 'edit' : 'new';
  }

  @action toggleSectionActive(name: SectionName) {
    let enabled = !this.sections[name].active;
    this.setSectionActive(name, enabled);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'toggled',
      object: 'section_switch',
      switch_enabled: enabled,
      config_section: name,
      config_type: this.configType,
    });
  }

  @action leaveImportConfig() {
    this.router.transitionTo('apps.app.settings.data.imports-exports.index', {
      queryParams: 'import-zendesk',
    });
  }

  @action saveDraft() {
    let { objectTypes, identityTypes } = this.activeObjectTypes;
    let result: 'success' | 'error' = 'success';

    if (objectTypes.length > 0) {
      this.doSave(objectTypes, identityTypes);
    } else {
      this.notificationsService.notifyError(
        this.intl.t(this.translationKey('notifications.no-mappings-error')),
      );
      result = 'error';
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'save_draft',
      result,
      object_types: objectTypes,
      identity_types: identityTypes,
      config_type: this.configType,
    });
  }

  @action async startImport() {
    let { objectTypes, identityTypes } = this.activeObjectTypes;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'start_migration',
      object_types: objectTypes,
      identity_types: identityTypes,
      config_type: this.configType,
    });

    this.migrationStarting = true;
    await taskFor(this.startImportTask).perform(objectTypes, identityTypes);
    this.migrationStarting = false;
    this.showStartImportDialog = false;
  }

  @action openImportDialog(importDialog: 'start-import' | 'import-again') {
    if (this.validateImportConfiguration()) {
      if (importDialog === 'start-import') {
        this.showStartImportDialog = true;
      } else {
        this.showImportAgainDialog = true;
      }
    }
  }

  validateImportConfiguration() {
    let activeSections = this.sectionNames.filter((section) => this.sections[section].active);
    if (activeSections.length === 0) {
      this.notificationsService.notifyError(
        this.intl.t(this.translationKey('notifications.no-active-sections')),
      );
      return false;
    }

    if (activeSections.includes('ticket')) {
      for (let step of ['admin', 'ticket-data'] as TabName[]) {
        if (!this.validateStep(step)) {
          return false;
        }
      }
    }

    return true;
  }

  validateStep = (step: TabName) => {
    switch (step) {
      case 'admin':
        return this.validateAdmin();
      case 'ticket-data':
        return this.validateTicketData();
    }
    return true;
  };

  private validateAdmin() {
    if (this.importConfigurationService.fallbackAdminId) {
      return true;
    }

    this.notificationsService.notifyError(
      this.intl.t(this.translationKey('sections.ticket.admin.errors.missing-default-admin')),
    );
    return false;
  }

  private validateTicketData() {
    // validate if the current selected customer and backoffice tickets have unmapped required attributes
    for (let visibility of ['public', 'private'] as MappingVisibility[]) {
      let ticketId = this.importConfigurationService.getTicketTypeId(visibility);
      if (!ticketId) {
        this.notificationsService.notifyError(
          this.intl.t(
            this.translationKey(
              `sections.ticket.ticket-data.errors.missing-${visibility}-ticket-type`,
            ),
          ),
        );
        return false;
      }
      let requiredMappings = this.importConfigurationService.listRequiredTicketMappings(
        ticketId,
        visibility,
      );

      for (let mapping of requiredMappings) {
        if (mapping.status !== 'done') {
          this.notificationsService.notifyError(
            this.intl.t(
              this.translationKey(
                `sections.ticket.ticket-data.errors.missing-required-${visibility}-attributes`,
              ),
            ),
          );
          return false;
        }
      }
    }
    return true;
  }

  translation = (path: string) => this.intl.t(this.translationKey(path));
  private translationKey = (path: string) =>
    `new-settings.data.imports-exports.import-zendesk.config-import-page.${path}`;

  @dropTask private *startImportTask(
    objectTypes: string[],
    identityTypes: string[],
  ): TaskGenerator<any> {
    let ret = yield this.importConfigurationService.startDataImport(objectTypes, identityTypes);
    // only redirect if there were no errors
    if (ret === true) {
      // Wait until the server has changed the status before redirecting to the migration overview
      yield taskFor(this.reloadMigrationStatusTask).perform(this.migrationStatus);
      this.router.transitionTo('apps.app.settings.data.imports-exports.index', {
        queryParams: 'import-zendesk',
      });
    }
  }

  @restartableTask private *reloadMigrationStatusTask(
    status: MigrationStatusEnum,
    delay = ENV.APP._1000MS,
  ) {
    this.migrationStatus = 'loading';

    // keep reloading until migrationStatus changes
    while (this.migrationStatus === 'loading' || this.migrationStatus === status) {
      yield timeout(delay);
      yield taskFor(this.loadMigrationStatusTask).perform();
    }
  }

  @keepLatestTask private *loadMigrationStatusTask(): TaskGenerator<void> {
    let response = yield get(
      `/ember/migrate_from_crm_integrations/data_import_status?app_id=${this.session.workspace.id}&integration_code=${this.args.integrationCode}`,
    );

    if (isPresent(response.data_import)) {
      this.migrationStatus = response.data_import.state;
    }
  }

  private setSectionActive(name: SectionName, activate: boolean) {
    let section = this.sections[name];

    section.active = activate;
    if (activate) {
      section.requires.forEach((s) => this.setSectionActive(s, activate));
    } else {
      section.requiredBy.forEach((s) => this.setSectionActive(s, activate));
    }
  }

  private setSectionFreeze(name: SectionName) {
    let section = this.sections[name];

    section.frozen = true;
    section.requires.forEach((s) => this.setSectionFreeze(s));
  }

  private get activeObjectTypes() {
    let activeSections = this.sectionNames
      .map((s) => this.sections[s])
      .filter((section) => section.active);

    let objectTypes = activeSections.map((section) => section.name);
    let identityTypes = objectTypes.includes('ticket') ? ['team', 'admin'] : [];

    return {
      objectTypes,
      identityTypes,
    };
  }

  private async doSave(objectTypes: string[], identityTypes: string[]) {
    this.isSaving = true;
    await this.importConfigurationService.storeConfiguration(objectTypes, identityTypes);
    this.notificationsService.notifyConfirmation(this.translation('notifications.draft-saved'));
    this.isSaving = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk::ConfigImport': typeof ConfigImport;
    'new-settings/data/imports-exports/import-zendesk/config-import': typeof ConfigImport;
  }
}
