/* import __COLOCATED_TEMPLATE__ from './ticket-status-modal.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

export interface Args {}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class TicketStatusModal extends Component<Signature> {
  @service declare intl: IntlService;

  destinationClass = (color: string) => `font-semibold text-${color}`;

  translation = (path: string) => this.intl.t(this.translationKey(path));
  private translationKey = (path: string) =>
    `new-settings.data.imports-exports.import-zendesk.config-import-page.ticket-status-modal.${path}`;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk::ConfigImport::Sections::Lib::TicketStatusModal': typeof TicketStatusModal;
    'new-settings/data/imports-exports/import-zendesk/config-import/sections/lib/ticket-status-modal': typeof TicketStatusModal;
  }
}
