/* import __COLOCATED_TEMPLATE__ from './select-admin-fallback.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type ImportConfigurationService from 'embercom/services/data/import-configuration-service';
import type Admin from 'embercom/models/admin';

export interface Args {}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class SelectAdminFallback extends Component<Signature> {
  @service declare appService: any;
  @service('data/import-configuration-service')
  declare importConfigurationService: ImportConfigurationService;
  @service declare intl: IntlService;

  get items() {
    let admins = this.appService.app.assignableAdmins;

    let options = admins.map((item: Admin) => {
      return {
        text: item.name,
        value: item.id,
        component:
          'new-settings/data/imports-exports/import-zendesk/config-import/sections/lib/select-admin-dropdown-option',
        componentAttrs: { model: item },
      };
    });
    return options;
  }

  get itemId() {
    let itemId: string | null = this.importConfigurationService.fallbackAdminId;

    if (this.items.some((item: { value: string }) => item.value === itemId)) {
      return itemId!;
    }

    return undefined;
  }

  get hasError() {
    return !this.itemId;
  }

  @action onSelectItem(value: string) {
    this.importConfigurationService.setFallbackAdminId(value);
  }

  @action onDeselectItem() {
    this.importConfigurationService.setFallbackAdminId(null);
  }

  translation = (path: string) => this.intl.t(this.translationKey(path));
  private translationKey = (path: string) =>
    `new-settings.data.imports-exports.import-zendesk.config-import-page.select-admin-fallback.${path}`;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk::ConfigImport::Sections::Lib::SelectAdminFallback': typeof SelectAdminFallback;
    'new-settings/data/imports-exports/import-zendesk/config-import/sections/lib/select-admin-fallback': typeof SelectAdminFallback;
  }
}
