/* import __COLOCATED_TEMPLATE__ from './migration-overview.hbs'; */
/* RESPONSIBLE TEAM: team-actions */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import { inject as service } from '@ember/service';
import ENV from 'embercom/config/environment';
import type IntlService from 'embercom/services/intl';
import type CrmTypeTranslationService from 'embercom/services/data/crm-type-translation-service';
import { type IntercomTypeTranslator } from 'embercom/services/data/crm-type-translation-service';
import { action } from '@ember/object';
import { dropTask, keepLatestTask, restartableTask } from 'ember-concurrency-decorators';
import { type TaskGenerator, timeout } from 'ember-concurrency';
import { get } from 'embercom/lib/ajax';
import type Session from 'embercom/services/session';
import Ember from 'ember';
import type ImportConfigurationService from 'embercom/services/data/import-configuration-service';
import { IntercomObjectTypes } from 'embercom/components/new-settings/data/imports-exports/import-zendesk';
import type Router from '@ember/routing/router-service';
import { type MigrationStatusEnum } from 'embercom/lib/apps/app/new-settings/data/imports-exports/import-zendesk/zendesk-context-loader';

export interface Args {
  integrationCode: string;
  isConnected: boolean;
  subdomain: string;
  updateConnectionData: (
    isConnected: boolean,
    subdomain: string,
    hasStartedMigration: boolean,
  ) => void;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

type ObjectBackendStatus = 'pending' | 'completed';
type ObjectStatusEnum = ObjectBackendStatus | 'loading' | 'draft' | 'cancelled' | 'none';

type SectionConfig = {
  active: boolean;
  frozen: boolean;
};

class ObjectStatus {
  objectType: string;
  @tracked status: ObjectStatusEnum;
  @tracked sectionConfig: SectionConfig;
  @tracked migrated: number;
  @tracked failed: number;
  @tracked lastUpdated: Date;

  constructor(
    objectType: string,
    sectionConfig: SectionConfig,
    status: ObjectStatusEnum,
    migrated: number,
    failed: number,
    lastUpdated: Date,
  ) {
    this.objectType = objectType;
    this.sectionConfig = sectionConfig;
    this.status = status;
    this.migrated = migrated;
    this.failed = failed;
    this.lastUpdated = lastUpdated;
  }
}

export class MigrationStatus {
  @tracked status: MigrationStatusEnum = 'none';
  @tracked objects: Map<string, ObjectStatus> = new Map<string, ObjectStatus>();

  constructor(status: MigrationStatusEnum = 'none') {
    this.status = status;
  }

  get isProcessing() {
    let processingStates = ['preparing', 'fanout_in_progress', 'processing'];
    return processingStates.includes(this.status);
  }

  get isLoading() {
    let loadingStates = ['loading', 'stopping'];
    return loadingStates.includes(this.status);
  }

  get needsReload() {
    let processingStates = ['preparing', 'fanout_in_progress', 'processing', 'stopping'];
    return processingStates.includes(this.status);
  }

  get isCompletedOrCancelled() {
    let completedStates = ['completed', 'completed_with_errors', 'cancelled'];
    return completedStates.includes(this.status);
  }
}

export default class MigrationOverview extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare intercomEventService: $TSFixMe;
  @service declare router: Router;

  @service('data/crm-type-translation-service')
  declare typeTranslationService: CrmTypeTranslationService;

  @service('data/import-configuration-service')
  declare importConfigurationService: ImportConfigurationService;

  @tracked migration: MigrationStatus = new MigrationStatus();
  @tracked migrationObjects: ObjectStatus[] = [];
  @tracked showCancelDialog = false;
  @tracked showDisconnectDialog = false;

  typeTranslator: IntercomTypeTranslator;
  integrationCode: string;
  loadMigrationStatus = taskFor(this.loadMigrationStatusTask);

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.typeTranslator = this.typeTranslationService.getTranslator(this.args.integrationCode)!;
    this.integrationCode = this.args.integrationCode;

    Object.values(IntercomObjectTypes).forEach((objectType) => {
      this.migration.objects.set(
        objectType,
        new ObjectStatus(
          this.typeTranslator.getCrmType(objectType)!,
          { active: false, frozen: false },
          'none',
          0,
          0,
          new Date(),
        ),
      );
    });

    if (this.args.isConnected) {
      this.migration.status = 'loading';
      taskFor(this.initializeMigrationOverviewStatusTask).perform();
    }
  }

  @action onDisconnect() {
    this.args.updateConnectionData(false, '', false);
  }

  @action onEditMigration() {
    this.router.transitionTo('apps.app.settings.data.imports-exports.import-zendesk');
  }

  @action stopMigration() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'stop_migration',
    });
    taskFor(this.stopMigrationTask).perform();
    this.showCancelDialog = false;
  }

  @dropTask *stopMigrationTask() {
    let status = this.migration.status;
    // set the status as 'loading' to show the spinner while we stop the migration and reload the status
    this.migration.status = 'loading' as MigrationStatusEnum;
    yield this.importConfigurationService.stopDataImport(this.integrationCode);
    // reload the status after cancelling the migration
    yield taskFor(this.reloadMigrationStatusTask).perform(status);

    // continue reloading if necessary
    if (this.migration.status === 'stopping') {
      yield taskFor(this.reloadMigrationStatusTask).perform('stopping');
    }
  }

  @keepLatestTask *loadMigrationStatusTask(): TaskGenerator<void> {
    let response = yield get(
      `/ember/migrate_from_crm_integrations/data_import_status?app_id=${this.session.workspace.id}&integration_code=${this.integrationCode}`,
    );

    this.migration.status = response.data_import?.state;

    response.import_object_types_statuses.forEach((json: any) => {
      let intercomType = this.typeTranslator.getIntercomType(json.object_type_name)!;
      let object = this.migration.objects.get(intercomType);
      if (object === undefined) {
        return;
      }

      object.migrated = json.migrated;
      object.failed = json.failed;
      if (json.last_updated !== null) {
        object.lastUpdated = new Date(json.last_updated);
      }

      // update the status only when it has changed, to reduce flickering
      let status = json.status;
      if (['cancelled'].includes(this.migration.status)) {
        status = this.migration.status;
      }
      if (object.status !== status) {
        object.status = status;
      }

      if (['cancelled', 'pending', 'completed'].includes(object.status)) {
        object.sectionConfig.active = true;
        object.sectionConfig.frozen = true;
      }
    });

    this.migrationObjects = Array.from(this.migration.objects.values());
  }

  @restartableTask *migrationStatusLiveReload(cond: () => boolean, delay = ENV.APP._5000MS) {
    // Ugly, but it's what is suggested in the guide here:
    // https://v221.ember-concurrency.com/docs/testing-debugging
    while (cond() && !Ember.testing) {
      yield this.loadMigrationStatus.perform();
      this.migrationObjects = Array.from(this.migration.objects.values());
      yield timeout(delay);
    }
  }

  @restartableTask *reloadMigrationStatusTask(
    status: MigrationStatusEnum,
    delay = ENV.APP._1000MS,
  ) {
    this.migration.status = 'loading';
    // the backend needs some time to process the changes, so there's no point on making the first call immediately
    yield timeout(delay);
    yield this.loadMigrationStatus.perform();

    // keep reloading until the state changes
    yield taskFor(this.migrationStatusLiveReload).perform(
      () => this.migration.status === 'loading' || this.migration.status === status,
      delay,
    );

    // to keep the live reload in case the migration is in progress or stopping
    yield timeout(delay);
    yield taskFor(this.liveReloadProcessingMigration).perform();
  }

  @restartableTask *liveReloadProcessingMigration() {
    yield taskFor(this.migrationStatusLiveReload).perform(() => this.migration.needsReload);
  }

  @keepLatestTask *initializeMigrationOverviewStatusTask(
    delay = ENV.APP._1000MS,
  ): TaskGenerator<any> {
    yield this.loadMigrationStatus.perform();
    yield timeout(delay);
    yield taskFor(this.liveReloadProcessingMigration).perform();
  }

  statusColor = (status: ObjectStatusEnum) => {
    switch (status) {
      case 'draft':
        return 'gray';
      case 'pending':
        return 'sky';
      case 'completed':
        return 'green';
      default:
        return 'red';
    }
  };

  maybeEmpty = (text: string, status: ObjectStatusEnum): string => {
    let empty = status === 'draft' || status === 'none';
    return empty ? '-' : text;
  };

  translation = (path: string) => this.intl.t(this.translationKey(path));
  private translationKey = (path: string) =>
    `new-settings.data.imports-exports.import-zendesk.migration-overview.${path}`;

  objectTypeTranslation = (crm: string, object: string) => {
    let intercomType = this.typeTranslator.getIntercomType(object)!;
    return this.translation(`objects.${crm}.${intercomType}`);
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk::MigrationOverview': typeof MigrationOverview;
    'new-settings/data/imports-exports/import-zendesk/migration-overview': typeof MigrationOverview;
  }
}
