/* import __COLOCATED_TEMPLATE__ from './template-category.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import type Template from 'embercom/models/template';

interface Args {
  allTemplates: Template[];
  templateCategory: $TSFixMe;
  shouldShowPaywallBadges: boolean;
  recordAnalyticsEvent: (event: string) => void;
  selectTemplate: (template: Template, isNew: boolean) => void;
  transitionToPreview: () => void;
  createWorkflow: (
    contentType: string | null,
    _deliveryChannel: null,
    _audienceType: null,
    templateId: string | null,
  ) => void;
}

export default class TemplateCategory extends Component<Args> {
  get templates() {
    let filteredTemplates = this.args.allTemplates.filter(
      (template) => this.args.templateCategory.id === template.objective,
    );
    if (this.args.templateCategory.showcaseIds) {
      filteredTemplates = filteredTemplates.filter((template) =>
        this.args.templateCategory.showcaseIds.includes(template.id),
      );
    }
    return filteredTemplates;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export interface Registry {
    'Outbound::NewContentModal::Workflows::TemplateCategory': typeof TemplateCategory;
  }
}
