/* import __COLOCATED_TEMPLATE__ from './sidebar-content.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import templateOnly from '@ember/component/template-only';
import type WhatsappIntegration from 'embercom/models/whatsapp/integration';

interface Signature {
  Args: {
    addWhatsAppNumber: () => void;
    selectedSidebarContent: string;
    whatsappIntegration?: WhatsappIntegration;
    requestVerification: (businessId: string) => void;
    isLoading: boolean;
    showMultiplePhoneNumbers: boolean;
    usePhoneNumber: () => void;
    selectedNumber: string;
    accountPhoneNumbers: any;
    onExit: () => void;
  };
}

const SidebarContent = templateOnly<Signature>();

export default SidebarContent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Whatsapp::SidebarContent': typeof SidebarContent;
  }
}
