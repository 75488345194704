/* import __COLOCATED_TEMPLATE__ from './delineating-conversations.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import Delays from 'embercom/lib/workflows/helpers/delays';
import type WhatsappIntegration from 'embercom/models/whatsapp/integration';
import type WhatsappSettings from 'embercom/models/whatsapp/settings';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { restartableTask } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';
import { timeout } from 'ember-concurrency';
import { pluralize } from 'ember-inflector';

interface DelineatingConversationsArgs {
  settings: WhatsappSettings;
  integrations: WhatsappIntegration[];
}

export default class DelineatingConversations extends Component<DelineatingConversationsArgs> {
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @tracked thresholdValue: number;
  @tracked thresholdUnit: string;

  constructor(owner: unknown, args: DelineatingConversationsArgs) {
    super(owner, args);
    this.thresholdValue = this.delays.getDelayValue(this.currentThreshold);
    this.thresholdUnit = this.delays.getDelayUnit(this.currentThreshold);
  }

  get integrations() {
    return this.args.integrations;
  }

  get hasIntegrations() {
    return this.integrations?.length > 0;
  }

  get settings() {
    return this.args.settings;
  }

  get delays() {
    return new Delays();
  }

  get currentThreshold() {
    return this.settings.delineationThresholdInSeconds;
  }

  get displayThreshold() {
    // @ts-ignore - The type definition provided by ember-inflector is wrong here. This is a valid invocation of `pluralize`.
    return pluralize(this.thresholdValue, this.thresholdUnit);
  }

  get unitOptions() {
    return [
      {
        value: 'minute',
        text: this.intl.t('new-settings.channels.whatsapp.delineating-conversations.minutes'),
      },
      {
        value: 'hour',
        text: this.intl.t('new-settings.channels.whatsapp.delineating-conversations.hours'),
      },
      {
        value: 'day',
        text: this.intl.t('new-settings.channels.whatsapp.delineating-conversations.days'),
      },
    ];
  }

  @action
  onThresholdValueChange(event: InputEvent) {
    let value = (event.target as HTMLInputElement).value;
    this.thresholdValue = Number(value);
    taskFor(this.saveDelineationSettings).perform();
  }

  @action
  onUnitSelect(unit: string) {
    this.thresholdUnit = unit;
    taskFor(this.saveDelineationSettings).perform();
  }

  @restartableTask
  *saveDelineationSettings() {
    yield timeout(200);
    let thresholdInSeconds = this.delays.getDelayInSeconds(this.thresholdValue, this.thresholdUnit);
    this.settings.delineationThresholdInSeconds = thresholdInSeconds;
    try {
      yield this.settings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('new-settings.channels.whatsapp.delineating-conversations.save-success', {
          displayThreshold: this.displayThreshold,
        }),
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'updated',
        object: 'new_conversation_threshold',
        place: 'settings_channels_whatsapp',
        section: 'open_new_conversations',
      });
    } catch (error) {
      console.error(error);
      this.notificationsService.notifyError(
        this.intl.t('new-settings.channels.whatsapp.delineating-conversations.save-error'),
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Whatsapp::DelineatingConversations': typeof DelineatingConversations;
    'new-settings/channels/whatsapp/delineating-conversations': typeof DelineatingConversations;
  }
}
