/* import __COLOCATED_TEMPLATE__ from './migrate-from-zendesk-card.hbs'; */
/* RESPONSIBLE TEAM: team-actions */

import templateOnlyComponent from '@ember/component/template-only';

interface Args {}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const MigrateFromZendeskCard = templateOnlyComponent<Signature>();
export default MigrateFromZendeskCard;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::MigrateFromZendeskCard': typeof MigrateFromZendeskCard;
    'onboarding/guide-library/migrate-from-zendesk': typeof MigrateFromZendeskCard;
  }
}
