/* import __COLOCATED_TEMPLATE__ from './template-card.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { type TEMPLATE_GROUPS } from 'embercom/lib/operator/custom-bots/constants';
import Component from '@glimmer/component';
import type Template from 'embercom/models/template';
import { AI_AGENT_EMAIL_TEMPLATE_ID } from 'embercom/lib/operator/resolution-bot/constants';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

type Args = {
  onClick: () => void;
  onHover?: () => void;
  group: (typeof TEMPLATE_GROUPS)[number]; // TODO after constants are converted to TS use a proper type here
  template: Template;
  isShowCaseTemplate?: boolean;
  shouldShowPaywallBadges: boolean;
  isFinTemplate?: boolean;
  showPill?: boolean;
  inEmptyState?: boolean;
  createWorkflow?: () => void;
};

export default class TemplateCard extends Component<{ Element: HTMLDivElement; Args: Args }> {
  @service declare intercomEventService: any;

  @tracked hoverActive = false;
  get requiredBillingFeature(): string {
    return this.args.template.ruleset.rulesetLinks.firstObject.object.config.requiredBillingFeature;
  }

  get isFinOverEmailTemplate(): boolean {
    return this.args.template.id === AI_AGENT_EMAIL_TEMPLATE_ID;
  }

  get workspaceUsageCount(): string {
    if (!this.args.template.metadata.numberOfWorkspacesUsing) {
      return '0';
    }
    return `${this.args.template.metadata.numberOfWorkspacesUsing / 1000}k`;
  }

  @action
  showPreview() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `template_card_preview`,
      place: 'workflows_overview',
      section: this.args.inEmptyState ? 'empty_state' : 'template_selector',
      template_id: this.args.template.id,
    });
    this.args.onClick();
  }

  @action
  createWorkflow() {
    if (this.args.createWorkflow) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: `template_card_use_template`,
        place: 'workflows_overview',
        section: this.args.inEmptyState ? 'empty_state' : 'template_selector',
        template_id: this.args.template.id,
      });
      this.args.createWorkflow();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::NewContentModal::Workflows::TemplateCard': typeof TemplateCard;
  }
}
