/* import __COLOCATED_TEMPLATE__ from './identify-users.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { restartableTask } from 'ember-concurrency-decorators';
import type WhatsappSettings from 'embercom/models/whatsapp/settings';
import type IntlService from 'embercom/services/intl';

interface IdentifyUsersArgs {
  settings: WhatsappSettings;
}

export default class IdentifyUsers extends Component<IdentifyUsersArgs> {
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;

  @restartableTask
  *togglePhoneBasedUserAssociation(newValue: boolean) {
    this.args.settings.phoneBasedUserAssociation = newValue;
    yield this.args.settings.save();
    this.notificationsService.notifyConfirmation(
      this.intl.t('components.settings.switch.setup.settings-saved'),
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Whatsapp::IdentifyUsers': typeof IdentifyUsers;
    'new-settings/channels/whatsapp/identify-users': typeof IdentifyUsers;
  }
}
