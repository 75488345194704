/* import __COLOCATED_TEMPLATE__ from './tab.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
// @ts-nocheck

import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import { groupBy, indexBy } from 'underscore';
import { getDomainName } from 'embercom/lib/email';
import type Brand from 'embercom/models/brand';
import type DkimSettings from 'embercom/models/dkim-settings';
import type CustomBounceSettings from 'embercom/models/custom-bounce-settings';
import type CustomEmailAddress from 'embercom/models/custom-email-address';
import type SenderEmailAddressSettings from 'embercom/models/sender-email-address-settings';
import type IntlService from 'embercom/services/intl';

interface Args {
  senderEmailAddressSettings: SenderEmailAddressSettings[];
  senderEmailAddresses: CustomEmailAddress[];
  bounceSettings: CustomBounceSettings[];
  dkimSettings: DkimSettings[];
  brands: Brand[];
}

interface Signature {
  Args: Args;
}

export interface DomainEmailAddress {
  id: string;
  email: string;
  verified: boolean;
  domainName: string;
  brandName: string;
  brandId: number;
  outboundStatus: string;
  forwardingEnabled: boolean;
  senderEmailAddresses: CustomEmailAddress[];
  senderEmailAddressSettings?: SenderEmailAddressSettings;
}

export interface Domain {
  name: string;
  isAuthenticated: boolean;
  bounceSettings: BounceSettings;
  dkimSettings: DkimSettings;
  addresses: DomainEmailAddress[];
}

export default class Tab extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked isAddressSidebarOpen = false;
  @tracked addressToUpdate?: DomainEmailAddress = null;
  @tracked selectedDomain?: Domain;

  @tracked namesPerEmail: any = null;
  @tracked namesInitialState: any = [];
  @tracked idsPerEmail: any = [];

  get domains(): Domain[] {
    /*
      Combine all email-related records (sender email addresses), grouped by email address
    */

    let senderEmailAddressSettingsByEmail = indexBy(
      this.args.senderEmailAddressSettings.toArray(),
      'email',
    );
    let allEmails = new Set([...Object.keys(senderEmailAddressSettingsByEmail)]);

    let allAddresses = [...allEmails].map((email) => {
      let senderEmailAddressSettings = senderEmailAddressSettingsByEmail[email];
      let senderEmailAddresses = senderEmailAddressSettings?.senderEmailAddresses || [];
      let senderEmailAddress = senderEmailAddresses?.firstObject;

      let brandId = senderEmailAddressSettings?.brandId || null;
      let brandName = senderEmailAddressSettings?.brandName || this.appService.app.name;
      let forwardingEnabled = senderEmailAddressSettings?.forwardingEnabled || false;

      // this comes from the dkim settings for the email and will be the same on all addresses
      let outboundStatus;
      if (senderEmailAddressSettings && !senderEmailAddressSettings.verified) {
        outboundStatus = this.intl.t(
          'new-settings.channels.email.addresses.table.unverified-email',
        );
      } else {
        outboundStatus = senderEmailAddress?.status || 'disabled';
      }

      return {
        id: senderEmailAddressSettings?.id,
        email,
        verified: senderEmailAddressSettings?.verified || senderEmailAddress?.verified,
        domainName: getDomainName(email),
        brandId,
        brandName,
        senderEmailAddresses,
        senderEmailAddressSettings,
        outboundStatus,
        forwardingEnabled,
      };
    });

    /*
      Reject temporary email addresses created for Fin Standalone
    */

    if (this.appService.app.canUseStandalone) {
      allAddresses = allAddresses.reject((address) => address.domainName.endsWith('.hirefin.com'));
    }

    /*
      Combine all domain-related records (bounce settings, DKIM settings, and
      email addresses), grouped by domain name
    */

    let bounceSettingsGrouped = groupBy(this.args.bounceSettings.toArray(), 'rootDomain');
    let dkimSettingsGrouped = groupBy(this.args.dkimSettings.toArray(), 'domain');
    let addressesGrouped = groupBy(allAddresses, 'domainName');
    let allDomainNames = new Set([
      ...Object.keys(bounceSettingsGrouped),
      ...Object.keys(dkimSettingsGrouped),
      ...Object.keys(addressesGrouped),
    ]);

    let allDomains = [...allDomainNames].map((domainName) => {
      // There is only one bounceSettings and one dkimSettings per domain
      let bounceSettings = bounceSettingsGrouped[domainName]?.[0];
      let dkimSettings = dkimSettingsGrouped[domainName]?.[0];
      let addresses = (addressesGrouped[domainName] || []).sort((a, b) =>
        a.email.localeCompare(b.email),
      );
      return {
        name: domainName,
        isAuthenticated:
          bounceSettings?.validRecordExists &&
          dkimSettings?.validRecordExists &&
          dkimSettings?.validDmarcExists,
        bounceSettings,
        dkimSettings,
        addresses,
      };
    });

    return allDomains.sort((a, b) => a.name.localeCompare(b.name));
  }

  @action
  openUpdateAddressSidebar(address: DomainEmailAddress) {
    this.addressToUpdate = address;
  }

  @action
  closeUpdateAddressSidebar() {
    this.addressToUpdate = null;
  }

  @action
  openDomainSidebar(domain: Domain) {
    this.selectedDomain = domain;
  }

  @action
  closeDomainSidebar() {
    this.selectedDomain = null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Addresses::Tab': typeof Tab;
  }
}
