/* import __COLOCATED_TEMPLATE__ from './new-content-modal.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import {
  objectTypes,
  objectNames,
  objectNamesToKeys,
} from 'embercom/models/data/matching-system/matching-constants';
import { objectives, analyticEventNames } from 'embercom/models/data/outbound/template-constants';

const OBJECT_TYPES_FOR_ROUTE = {
  'apps.app.outbound.chat.index': objectTypes.chat,
  'apps.app.outbound.post.index': objectTypes.post,
  'apps.app.outbound.email.index': objectTypes.email,
  'apps.app.outbound.tour.index': objectTypes.tour,
  'apps.app.outbound.banners.index': objectTypes.banner,
  'apps.app.outbound.carousel.index': objectTypes.carousel,
  'apps.app.outbound.custom-bot.index': objectTypes.customBot,
  'apps.app.outbound.push.index': objectTypes.push,
  'apps.app.outbound.series.index': objectTypes.series,
  'apps.app.automation.resolution-bot.behaviors.index': objectTypes.resolutionBotBehavior,
  'apps.app.outbound.sms.index': objectTypes.sms,
  'apps.app.outbound.survey.index': objectTypes.survey,
  'apps.app.outbound.tooltips.index': objectTypes.tooltipGroup,
  'apps.app.outbound.news.news-items.index': objectTypes.newsItem,
  'apps.app.outbound.checklists.index': objectTypes.checklist,
  'apps.app.outbound.whatsapp.index': objectTypes.whatsapp,
};
export const OUTBOUND_ALL_ROUTE = 'apps.app.outbound.all';
export const OUTBOUND_VIEW_ROUTE = 'apps.app.outbound.views';
export const OUTBOUND_NEWS_ITEM = 'apps.app.outbound.news.news-items.index';

export default class NewContentModal extends Component {
  @service router;
  @service appService;
  @service intercomEventService;
  @service outboundHomeService;

  @tracked selectedContentType;
  @tracked selectedObjective;
  @tracked selectedTemplate = this.args.selectedTemplate;

  @tracked creatingFromScratch = false;
  @tracked selectedEmailTemplate;
  @tracked isFromTemplateSelector = false;

  constructor() {
    super(...arguments);

    // We can get on the `apps.app.automation.workflows-overview` route where we intend
    // `selectedContentType` to be undefined, hence objectType being undefined.
    if (this.args.restrictToObjectType) {
      this.selectedContentType = this.args.restrictToObjectType;
    } else {
      this.selectedContentType = this.objectType;
    }

    if (!this.isInOutboundAll) {
      this.selectedObjective = objectives.all;
    } else {
      let { queryParams } = this.router.currentRoute;
      if (objectTypes.hasOwnProperty(queryParams?.open_content_creation_modal)) {
        this.selectedContentType = objectTypes[queryParams.open_content_creation_modal];
        this.selectedObjective = null;
      }
    }
  }

  get objectType() {
    let selectedObjectTypes;
    selectedObjectTypes = this.outboundHomeService.selectedObjectTypes
      ?.map((objectType) => Number(objectType) || Number(objectNamesToKeys[objectType]))
      .filter(
        (objectType) =>
          Number(objectType) !== objectTypes.legacyMessageEmail &&
          Number(objectType) !== objectTypes.legacyMessagePush,
      );
    if (selectedObjectTypes?.length === 1) {
      return selectedObjectTypes[0];
    }
    return OBJECT_TYPES_FOR_ROUTE[this.router.currentRouteName];
  }

  get app() {
    return this.appService.app;
  }

  get isInOutboundAll() {
    return (
      this.router.currentRouteName === OUTBOUND_ALL_ROUTE ||
      this.isInNewsItem ||
      this.isInOutboundView
    );
  }

  get isInOutboundView() {
    return this.router.currentRouteName === OUTBOUND_VIEW_ROUTE;
  }

  get isInNewsItem() {
    return this.router.currentRouteName === OUTBOUND_NEWS_ITEM;
  }

  get isSelectEmailTemplate() {
    return (
      this.creatingFromScratch ||
      (this.selectedTemplate && this.selectedTemplate.objectType === objectTypes.email)
    );
  }

  get objectTypes() {
    return objectTypes;
  }

  @action
  back() {
    this.selectedTemplate = undefined;
    this.creatingFromScratch = false;
  }

  @action
  trackEvent(object) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'selected',
      object,
      context: 'template_modal',
    });
  }

  @action
  onContentTypeSelect(contentType) {
    this.selectedObjective = undefined;
    this.selectedContentType = contentType;
    this.trackEvent(objectNames[contentType]);
  }

  @action
  onObjectiveSelect(objective) {
    this.trackEvent(analyticEventNames[objective]);

    if (!this.isInOutboundAll) {
      this.selectedObjective = objective;
      return;
    }

    this.selectedObjective = objective;
    this.selectedContentType = undefined;
  }

  @action
  handleContentCreation(
    messageType = null,
    deliveryChannel = null,
    audienceType = null,
    templateId = null,
  ) {
    if (objectTypes.email === messageType) {
      this.creatingFromScratch = true;
    } else {
      this.args.createContent(messageType, deliveryChannel, audienceType, templateId);
    }
  }

  @action
  updateSelectedEmailTemplate(identifier) {
    this.selectedEmailTemplate = identifier;
  }
}
