/* import __COLOCATED_TEMPLATE__ from './connected-accounts.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import type WhatsappIntegration from 'embercom/models/whatsapp/integration';

interface Signature {
  Args: {
    addWhatsAppNumber: () => void;
    whatsappIntegrations: WhatsappIntegration[];
    connectIntegration: (whatsappIntegration: WhatsappIntegration) => void;
    showRemoveModal: (whatsappIntegration: WhatsappIntegration) => void;
    openWhatsappManager: (whatsappIntegration: WhatsappIntegration) => void;
  };
}

export default class ConnectedAccounts extends Component<Signature> {
  get lastUnverifiedBusinessWhatsappIntegration() {
    return this.args.whatsappIntegrations.findBy('businessVerificationStatus', 'not_verified');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Whatsapp::ConnectedAccounts': typeof ConnectedAccounts;
  }
}
