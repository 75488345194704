/* import __COLOCATED_TEMPLATE__ from './tags.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
import Component from '@glimmer/component';

interface Args {
  model: any[];
}

interface Signature {
  Args: Args;
  Element: never;
}

export default class Tags extends Component<Signature> {
  get nonDeletedTagsOrSegments() {
    return this.args.model.filter((tag) => !tag.isDeleted);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::Tags': typeof Tags;
  }
}
