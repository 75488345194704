/* import __COLOCATED_TEMPLATE__ from './side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type Conversation from 'embercom/objects/inbox/conversation';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type FinAnswer from 'embercom/objects/inbox/renderable/fin-answer';

interface FinAnswerRenderablePart extends RenderablePart {
  renderableData: FinAnswer;
}
interface Signature {
  Element: HTMLDivElement;
  Args: {
    onClose: () => void;
    isOpen: boolean;
    part: FinAnswerRenderablePart | null;
    conversation: Conversation;
    partGroup: PartGroup;
    isStandalone?: boolean;
  };
}

export default class SideDrawer extends Component<Signature> {
  @service intercomEventService: any;

  @action close(): void {
    this.intercomEventService.trackEvent('closed-improve-this-answer');
    this.args.onClose();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::AnswerDebugger::SideDrawer': typeof SideDrawer;
  }
}
