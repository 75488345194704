/* RESPONSIBLE TEAM: team-product-exploration */
import { type ComponentLike } from '@glint/template';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import RouteRegexes from 'embercom/lib/route-regexes';
import MissingContactInfoAlert from 'embercom/components/new-settings/workspace/security/missing-contact-info-alert';
import NewHelpCenterOption from 'embercom/components/new-settings/helpcenter/new-help-center-option';
import DefaultHelpCenterOption from '../helpcenter/default-help-center-option';
import NewPricingOption from 'embercom/components/new-settings/workspace/new-pricing-option';
import AppStoreOption from 'embercom/components/new-settings/app-settings/app-store-option';

type NewHelpCenterOptionProps = {
  itemTitle: string;
  icon: InterfaceIconName;
  type: 'card' | 'menu-item';
};

type DefaultHelpCenterOptionProps = {
  icon: InterfaceIconName;
  type: 'card' | 'menu-item';
};

export interface NavItem<T = any> {
  icon: InterfaceIconName;
  key: string;
  route?: string;
  gaRoute?: string;
  routeWithParams?: boolean;
  params?: Record<string, string>;
  routeRegex?: string | RegExp;
  titleIconComponent?: ComponentLike;
  hideOnFeatureFlag?: string;
  showOnFeatureFlag?: string;
  model?: $TSFixMe;
  customRenderer?: ComponentLike<T>;
  customDisplayCondition?: (param: any) => void;
  externalLink?: boolean;
}

export interface NavSection {
  keyword: string;
  routeRegex: string | RegExp;
  hasNoChildren: boolean;
  items: NavItem[];
  route: string;
  showOnlyOnFeatureFlag?: string;
  customDisplayCondition?: (app: $TSFixMe) => boolean;
}

const WORKSPACE_SUBNAV_ITEMS: NavItem[] = [
  {
    icon: 'long-text',
    key: 'general',
    route: 'apps.app.settings.workspace.general',
  },
  {
    icon: 'creditcard',
    key: 'billing',
    route: 'apps.app.billing',
    gaRoute: 'apps.app.settings.workspace.billing',
  },
  {
    icon: 'stars',
    key: 'new-pricing',
    customRenderer: NewPricingOption,
  },
  {
    icon: 'person-square-fill',
    key: 'teammates',
    route: 'apps.app.settings.workspace.teammates',
  },
  {
    icon: 'clock-filled',
    key: 'office-hours',
    route: 'apps.app.settings.workspace.office-hours',
  },
  {
    icon: 'brands',
    key: 'brands',
    route: 'apps.app.settings.workspace.brands',
    showOnFeatureFlag: 'multibrand-email-open-beta',
  },
  {
    icon: 'qual-identify',
    key: 'security',
    route: 'apps.app.settings.workspace.security',
    titleIconComponent: MissingContactInfoAlert,
  },
  {
    icon: 'gift',
    key: 'referrals',
    route: 'apps.app.settings.workspace.referrals',
    showOnFeatureFlag: 'team-purchase-experience-referrals',
  },
];

const HELPDSK_SUBNAV_ITEMS: NavItem[] = [
  {
    icon: 'multiple-people',
    key: 'team-inboxes',
    route: 'apps.app.settings.helpdesk.teams',
    customDisplayCondition: (app: $TSFixMe) => !!app.canUseHelpdeskSetup,
  },
  {
    icon: 'multiple-people',
    key: 'teams',
    route: 'apps.app.settings.helpdesk.teams',
    customDisplayCondition: (app: $TSFixMe) => !app.canUseHelpdeskSetup,
  },
  {
    icon: 'assignment',
    key: 'assignments',
    route: 'apps.app.settings.helpdesk.assignments',
    customDisplayCondition: (app: $TSFixMe) => !app.canUseHelpdeskSetup,
  },
  {
    icon: 'assignment',
    key: 'assignments-inbox',
    route: 'apps.app.settings.helpdesk.assignments',
    customDisplayCondition: (app: $TSFixMe) => !!app.canUseHelpdeskSetup,
  },
  {
    icon: 'saved-reply',
    key: 'macros',
    route: 'apps.app.settings.helpdesk.macros',
  },
  {
    icon: 'ticket',
    key: 'tickets',
    route: 'apps.app.settings.helpdesk.tickets',
  },
  {
    icon: 'sla',
    key: 'sla',
    route: 'apps.app.settings.helpdesk.sla',
    customDisplayCondition: (app: $TSFixMe) => !!app.canUseInboxSlas || !!app.canUseHelpdeskSetup,
  },
  {
    icon: 'rules',
    key: 'rules',
    route: 'apps.app.settings.helpdesk.rules',
    hideOnFeatureFlag: 'workflows-net-new-experience',
  },
];

const AI_AUTOMATION_SUBNAV_ITEMS: NavItem[] = [
  {
    icon: 'rounded-check',
    key: 'external-ai',
    route: 'apps.app.settings.ai-automation.external-ai',
    showOnFeatureFlag: 'answerbot-ai-global-opt-out',
  },
  {
    icon: 'fin',
    key: 'fin-ai-agent',
    route: 'apps.app.settings.ai-automation.fin-ai-agent',
  },
  {
    icon: 'ai',
    key: 'fin-copilot-helpdesk-ai',
    route: 'apps.app.settings.ai-automation.helpdesk-ai',
    customDisplayCondition: (app: $TSFixMe) => !!app.canAccessAiAssistBeta,
  },
  {
    icon: 'auto-message',
    key: 'automation',
    route: 'apps.app.settings.ai-automation.automation',
  },
];

const APPS_SUBNAV_ITEMS: NavItem[] = [
  {
    icon: 'app-store',
    key: 'browse-all-apps',
    customRenderer: AppStoreOption,
  },
  {
    icon: 'webhook',
    key: 'custom-actions',
    route: 'apps.app.settings.app-settings.custom-actions',
  },
  {
    icon: 'webhook',
    key: 'legacy-webhooks',
    route: 'apps.app.settings.app-settings.legacy-webhooks',
    showOnFeatureFlag: 'team-product-exploration-ia-settings-ga',
  },
  {
    icon: 'multiplatform',
    key: 'authentication',
    route: 'apps.app.settings.app-settings.authentication',
  },
  {
    icon: 'code',
    key: 'developer-hub',
    route: 'apps.app.developer-hub',
    externalLink: true,
  },
];

// Generate a list of subnav items for data with tags people companies conversation-tickets custom-objects imports-exports as a key in the objects
export const DATA_SUBNAV_ITEMS: NavItem[] = [
  {
    icon: 'tag',
    key: 'tags',
    route: 'apps.app.settings.data.tags',
  },
  {
    icon: 'recipient-data',
    key: 'people',
    route: 'apps.app.settings.data.people',
    params: { tab: 'attributes' },
  },
  {
    icon: 'multiple-people',
    key: 'audiences',
    route: 'apps.app.settings.data.audiences',
  },
  {
    icon: 'all-companies',
    key: 'companies',
    route: 'apps.app.settings.data.companies',
    params: { tab: 'attributes' },
  },
  {
    icon: 'attribute-titles',
    key: 'conversation-tickets',
    route: 'apps.app.settings.data.conversation-tickets',
  },
  {
    icon: 'custom-objects',
    key: 'custom-objects',
    route: 'apps.app.settings.data.custom-objects',
  },
  {
    icon: 'export',
    key: 'imports-exports',
    route: 'apps.app.settings.data.imports-exports.index',
    params: { tab: 'import-zendesk' },
  },
];

export const PROACTIVE_SUBNAV_ITEMS: NavItem[] = [
  {
    icon: 'sessions',
    key: 'subscriptions',
    route: 'apps.app.settings.proactive-support.subscriptions',
    gaRoute: 'apps.app.settings.proactive-support.subscriptions',
  },
  {
    icon: 'news-filled',
    key: 'newsfeeds',
    route: 'apps.app.settings.proactive-support.newsfeeds',
    gaRoute: 'apps.app.settings.proactive-support.newsfeeds',
  },
  {
    icon: 'label',
    key: 'news-labels',
    route: 'apps.app.settings.proactive-support.news-labels',
    gaRoute: 'apps.app.settings.proactive-support.news-labels',
  },
  {
    icon: 'gallery',
    key: 'customization',
    route: 'apps.app.settings.proactive-support.customization',
    gaRoute: 'apps.app.settings.proactive-support.customization',
  },
];

export const HELPCENTER_SUBNAV_ITEMS: NavItem<
  NewHelpCenterOptionProps | DefaultHelpCenterOptionProps
>[] = [
  {
    icon: 'article',
    key: 'workspace-helpcenter',
    customRenderer: DefaultHelpCenterOption,
  },
  {
    icon: 'gallery',
    key: 'all-help-centers',
    route: 'apps.app.settings.helpcenter.all',
    showOnFeatureFlag: 'team-product-exploration-ia-settings-ga',
  },
  {
    icon: 'new',
    key: 'new-help-center',
    showOnFeatureFlag: 'team-product-exploration-ia-settings-ga',
    customRenderer: NewHelpCenterOption,
  },
];

const CHANNELS_SUBNAV_ITEMS: NavItem[] = [
  {
    icon: 'messenger',
    key: 'messenger',
    route: 'apps.app.settings.channels.messenger.web',
    params: { tab: 'content', section: 'layout-and-spaces' },
    routeRegex: RouteRegexes.newSettings.messenger.root,
  },
  {
    icon: 'email',
    key: 'email',
    route: 'apps.app.settings.channels.email',
    params: { tab: 'email-forwarding' },
  },
  {
    icon: 'phone',
    key: 'phone',
    route: 'apps.app.settings.channels.phone',
  },
  {
    icon: 'whatsapp',
    key: 'whatsapp',
    route: 'apps.app.settings.channels.whatsapp',
    showOnFeatureFlag: 'team-product-guidance-new-social-channels',
  },
  {
    icon: 'switch',
    key: 'switch',
    route: 'apps.app.settings.channels.switch',
  },
  {
    icon: 'sms',
    key: 'sms',
    route: 'apps.app.settings.channels.sms',
  },
  {
    icon: 'app-store',
    key: 'social-channels',
    route: 'apps.app.settings.channels.social-channels',
    hideOnFeatureFlag: 'team-product-guidance-new-social-channels',
  },
  {
    icon: 'app-store',
    key: 'social-channels-v2',
    route: 'apps.app.settings.channels.social-channels',
    showOnFeatureFlag: 'team-product-guidance-new-social-channels',
  },
  {
    icon: 'long-text',
    key: 'all',
    route: 'apps.app.settings.channels.all',
    showOnFeatureFlag: 'team-product-guidance-channel-awareness',
  },
];

const PERSONAL_SUBNAV_ITEMS: NavItem[] = [
  {
    icon: 'long-text',
    key: 'details',
    route: 'apps.app.settings.personal.details',
  },
  {
    icon: 'owner',
    key: 'preferences',
    route: 'apps.app.settings.personal.preferences',
  },
  {
    icon: 'notification',
    key: 'notifications',
    route: 'apps.app.settings.personal.notifications',
  },
  {
    icon: 'visible',
    key: 'visible-to-you',
    route: 'apps.app.settings.personal.visible-to-you',
  },
  {
    icon: 'code-block',
    key: 'authentication',
    route: 'apps.app.settings.personal.authentication',
  },
  {
    icon: 'screenshare',
    key: 'account-access',
    route: 'apps.app.settings.personal.account-access',
  },
];

export const SETTINGS_NAV_ITEMS: NavSection[] = [
  {
    keyword: 'home',
    routeRegex: RouteRegexes.newSettings.home,
    hasNoChildren: true,
    items: [],
    route: 'apps.app.settings.index',
  },
  {
    keyword: 'workspace',
    routeRegex: RouteRegexes.newSettings.workspace,
    hasNoChildren: false,
    items: WORKSPACE_SUBNAV_ITEMS,
    route: 'apps.app.settings.workspace.general',
  },
  {
    keyword: 'channels',
    routeRegex: RouteRegexes.newSettings.channels,
    hasNoChildren: false,
    items: CHANNELS_SUBNAV_ITEMS,
    route: 'apps.app.settings.channels.messenger',
  },
  {
    keyword: 'helpdesk',
    routeRegex: RouteRegexes.newSettings.helpdesk,
    hasNoChildren: false,
    items: HELPDSK_SUBNAV_ITEMS,
    route: 'apps.app.settings.helpdesk.teams',
  },
  {
    keyword: 'ai-automation',
    routeRegex: RouteRegexes.newSettings.aiAutomation,
    hasNoChildren: false,
    items: AI_AUTOMATION_SUBNAV_ITEMS,
    route: 'apps.app.settings.ai-automation.fin-ai-agent',
  },
  {
    keyword: 'app-settings',
    routeRegex: RouteRegexes.newSettings.apps,
    hasNoChildren: false,
    items: APPS_SUBNAV_ITEMS,
    route: 'apps.app.appstore',
  },
  {
    keyword: 'data',
    routeRegex: RouteRegexes.newSettings.data,
    hasNoChildren: false,
    items: DATA_SUBNAV_ITEMS,
    route: 'apps.app.settings.data.people',
  },
  {
    keyword: 'helpcenter',
    routeRegex: RouteRegexes.newSettings.helpcenter,
    hasNoChildren: false,
    items: HELPCENTER_SUBNAV_ITEMS,
    route: 'apps.app.settings.helpcenter.workspace-helpcenter',
  },
  {
    keyword: 'proactive-support',
    routeRegex: RouteRegexes.newSettings.proactive,
    hasNoChildren: false,
    items: PROACTIVE_SUBNAV_ITEMS,
    route: 'apps.app.settings.proactive-support.subscriptions',
  },
  {
    keyword: 'personal',
    routeRegex: RouteRegexes.newSettings.personal,
    hasNoChildren: false,
    items: PERSONAL_SUBNAV_ITEMS,
    route: 'apps.app.settings.personal.details',
    customDisplayCondition: (app: { hasOptedInForProductIa: boolean }) =>
      app.hasOptedInForProductIa,
  },
];
