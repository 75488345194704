/* import __COLOCATED_TEMPLATE__ from './demo-preview.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { modifier } from 'ember-modifier';

export type DemoType = 'default' | 'guidance';

interface DemoPreviewSignature {
  Element: any;
  Args: {
    appId?: number | undefined;
    shareableFinToken?: any;
    languageOverride?: string;
    customActionId?: any;
    demoType?: DemoType;
    afterPreviewLoaded?: () => void;
  };
}

export default class DemoPreview extends Component<DemoPreviewSignature> {
  @service declare appService: $TSFixMe;

  get appId() {
    return this.args.appId || this.appService.app.id;
  }

  @action
  onIframeLoaded() {
    this.args.afterPreviewLoaded?.();
  }

  bindIFrame = modifier((el) => {
    let iframe = document.createElement('iframe');

    iframe.addEventListener('load', this.onIframeLoaded);

    // Add `&local_client=true` to the end of the URL to see changes to intercom-js code in the preview.
    let iframeUrl = `${window.location.origin}/hosted_messenger/${this.appId}/fin_demo_preview?preview_only=true&intercom_disable_cookies=true`;

    if (
      !this.appService.app.canUseFeature('ai-agent-multilingual-remove-preview-languages-select')
    ) {
      iframeUrl += `&language_override=${this.args.languageOverride ?? 'en'}`;
    }
    if (this.args.shareableFinToken) {
      iframeUrl += `&source=shareablefinpreview&token=${this.args.shareableFinToken}`;
    }
    if (this.args.customActionId) {
      iframeUrl += `&custom_action_id=${this.args.customActionId}`;
    }
    if (this.args.demoType) {
      iframeUrl += `&demo_type=${this.args.demoType}`;
    }

    iframe.src = iframeUrl;
    iframe.setAttribute('id', 'hosted-messenger-fin-demo-preview');

    el.replaceChildren(iframe);

    return () => {
      iframe.removeEventListener('load', this.onIframeLoaded);
    };
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    DemoPreview: typeof DemoPreview;
    'Operator::Fin::DemoPreview': typeof DemoPreview;
  }
}
