/* import __COLOCATED_TEMPLATE__ from './message-templates.hbs'; */
/* RESPONSIBLE TEAM: team-channels */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type WhatsappIntegration from 'embercom/models/whatsapp/integration';

interface MessageTemplatesArgs {
  whatsappIntegrations: WhatsappIntegration[];
}

export default class MessageTemplates extends Component<MessageTemplatesArgs> {
  @service declare intercomEventService: { trackAnalyticsEvent: Function };

  @tracked private _selectedIntegration?: WhatsappIntegration;

  constructor(owner: unknown, args: MessageTemplatesArgs) {
    super(owner, args);
    this._selectedIntegration = this.firstConnectedIntegration;
  }

  get whatsappIntegrations() {
    return this.args.whatsappIntegrations;
  }

  get connectedWhatsappIntegrations() {
    return this.whatsappIntegrations.filter((integration) => integration.isPhoneNumberConnected);
  }

  get firstConnectedIntegration() {
    return this.connectedWhatsappIntegrations[0];
  }

  get selectedIntegration() {
    return (
      this.whatsappIntegrations.findBy('id', this._selectedIntegration?.id) ??
      this.firstConnectedIntegration
    );
  }

  set selectedIntegration(value: WhatsappIntegration | undefined) {
    this._selectedIntegration = value;
  }

  get integrationsDropdownData() {
    let dropdownItems = this.connectedWhatsappIntegrations.map((integration) => {
      return {
        text: integration.displayString,
        value: integration,
        isSelected: this.selectedIntegration?.id === integration.id,
      };
    });

    return [
      {
        hasItemsMarkableAsSelected: true,
        items: dropdownItems,
      },
    ];
  }

  get hasMultipleConnectedIntegrations() {
    return this.connectedWhatsappIntegrations.length > 1;
  }

  @action
  selectIntegration(integration?: WhatsappIntegration) {
    this.selectedIntegration = integration;
  }

  @action
  openManageTemplates() {
    if (this.selectedIntegration) {
      let { whatsappAccountId, businessId } = this.selectedIntegration;
      let url = `https://business.facebook.com/wa/manage/message-templates/?waba_id=${whatsappAccountId}&business_id=${businessId}`;
      safeWindowOpen(url, '_blank');
      this.intercomEventService.trackAnalyticsEvent({
        action: 'opened',
        object: 'manage_templates',
        place: 'whatsapp',
        section: 'settings',
        whatsapp_account_id: whatsappAccountId,
        business_id: businessId,
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Whatsapp::MessageTemplates': typeof MessageTemplates;
    'new-settings/channels/whatsapp/message-templates': typeof MessageTemplates;
  }
}
