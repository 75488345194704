/* import __COLOCATED_TEMPLATE__ from './templates-list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import type Template from 'embercom/models/template';
import type SeriesTemplate from 'embercom/models/series/template';
import templateOnlyComponent from '@ember/component/template-only';

type TemplateTypes = Template | SeriesTemplate;

interface Signature {
  Args: {
    templates: TemplateTypes[];
    onSelect: (template: TemplateTypes) => void;
    isEmptyState: boolean;
    showBackgroundColor: boolean;
    showContentTypeIcons: boolean;
  };
}

const TemplatesList = templateOnlyComponent<Signature>();
export default TemplatesList;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::NewContentModal::TemplatesList': typeof TemplatesList;
    'outbound/new-content-modal/templates-list': typeof TemplatesList;
  }
}
