/* import __COLOCATED_TEMPLATE__ from './select-object.hbs'; */
/* RESPONSIBLE TEAM: team-actions */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import { type IntercomObject } from 'embercom/services/data/import-configuration-service';
import type ImportConfigurationService from 'embercom/services/data/import-configuration-service';
import type IdentityMapping from 'embercom/models/crm/identity-mapping';
import { tracked } from '@glimmer/tracking';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export interface Args {
  integrationCode: string;
  objectType: string;
  mapping: IdentityMapping;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

type MappingOption = { text: string; value: string };

export default class SelectObject extends Component<Signature> {
  @service('data/import-configuration-service')
  declare importConfigurationService: ImportConfigurationService;
  @service declare intl: IntlService;
  defaultIcon: InterfaceIconName | undefined = 'unsubscribed';

  mapping: IdentityMapping;

  @tracked items: MappingOption[];
  @tracked selectedItem: string | undefined;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.mapping = args.mapping;
    this.items = this.getMappingOptions();
    this.selectedItem = this.selectedOption;
  }

  getMappingOptions() {
    let mappingAlreadyUsedProps = {
      isDisabled: true,
      tooltipText: this.translation('object-already-assigned'),
    };

    let options = this.importConfigurationService
      .listAssignableIntercomObjects(this.mapping)
      .map((object: IntercomObject) => {
        let selectItem = {
          text: object.name,
          value: object.id,
          component: 'settings/data-import/modal/team-or-admin-dropdown-option',
          componentAttrs: { model: object.model },
        };

        let alreadyAssigned =
          object.id !== this.args.mapping.intercomObjectId &&
          this.importConfigurationService.objectAssigned(object);

        if (alreadyAssigned) {
          return { ...selectItem, ...mappingAlreadyUsedProps };
        }

        return selectItem;
      });

    return options;
  }

  @action refreshMappingOptions() {
    this.items = this.getMappingOptions();
  }

  @action onSelectItem(id: string) {
    this.mapping.intercomObjectId = id;
    this.mapping.intercomObjectType = this.args.objectType;
    this.mapping.status = 'done';
    this.selectedItem = id;
  }

  @action onSkipItem() {
    this.mapping.intercomObjectId = '';
    this.mapping.intercomObjectType = '';
    this.mapping.status = 'skip';
    this.selectedItem = undefined;
  }

  get hasFilter(): boolean {
    return this.items.length > 7;
  }

  get selectedOption(): string | undefined {
    if (this.mapping.status === 'skip' || this.mapping.status === 'pending') {
      return undefined;
    }
    return this.mapping.intercomObjectId;
  }

  get defaultLabel() {
    return this.translation(this.mapping.status === 'skip' ? 'skip' : 'default');
  }

  translation = (path: string) => this.intl.t(this.translationKey(path));
  private translationKey = (path: string) =>
    `new-settings.data.imports-exports.import-zendesk.config-import-page.select-object.${this.args.objectType}.${path}`;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk::ConfigImport::Sections::Lib::SelectObject': typeof SelectObject;
    'new-settings/data/imports-exports/import-zendesk/config-import/sections/lib/select-object': typeof SelectObject;
  }
}
