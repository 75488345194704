/* import __COLOCATED_TEMPLATE__ from './default-reply-address.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type SenderEmailAddressSettings from 'embercom/models/sender-email-address-settings';
import type Brand from 'embercom/models/brand';
import { tracked } from '@glimmer/tracking';
import type ApplicationInstance from '@ember/application/instance';
import type DomainService from 'embercom/services/domain-service';

interface Args {
  brands: Brand[];
}

interface Signature {
  Args: Args;
}

interface ReplyAddressItem {
  value: string | number;
  text: string;
  component: string;
  componentAttrs?: {
    verified?: boolean;
    isSelected?: boolean;
    isAuthenticated?: any;
  };
  isDisabled?: boolean;
}

export default class DefaultReplyAddress extends Component<Signature> {
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare domainService: DomainService;
  @tracked selectedSenderEmailAddressSettingsId?: string;

  constructor(owner: ApplicationInstance, args: Args) {
    super(owner, args);
    this.selectedSenderEmailAddressSettingsId = this.defaultSenderEmailAddressSettingsId;
  }

  get defaultBrand() {
    return this.args.brands.find((brand) => brand.isDefault);
  }

  get defaultSenderEmailAddressSettingsId() {
    let defaultSettings = this.authenticatedDomainsAddresses.find(
      (address) =>
        address.senderEmailAddressSettings?.id ===
        String(this.defaultBrand?.senderEmailAddressSettingsId),
    );
    return defaultSettings?.senderEmailAddressSettings?.id ?? undefined;
  }

  get authenticatedDomainsAddresses() {
    let authenticatedDomains = this.domainService.domains.filter(
      (domain) => domain.isAuthenticated,
    );
    let domainAddresses = authenticatedDomains.map((domain) => domain.addresses).flat();
    return domainAddresses;
  }

  get defaultReplyAddressItems(): ReplyAddressItem[] {
    let eligibleAddresses = this.authenticatedDomainsAddresses.filter(
      (address) => address.verified && address.senderEmailAddressSettings,
    );
    let items = eligibleAddresses?.length
      ? eligibleAddresses.map((address) => ({
          value: address.senderEmailAddressSettings?.id ?? 0,
          text: address.senderEmailAddressSettings?.email ?? '',
          component: 'new-settings/channels/email/advanced/email-dropdown-item',
          componentAttrs: {
            verified: address.verified,
            id: address.senderEmailAddressSettings?.id,
            isSelected:
              this.selectedSenderEmailAddressSettingsId === address.senderEmailAddressSettings?.id,
          },
        }))
      : [
          {
            value: 0,
            text: '',
            component: 'new-settings/channels/email/advanced/email-dropdown-empty-list',
            isDisabled: true,
          },
        ];
    return items;
  }

  @action
  async onSelectSenderEmailAddressSetting(selectedItem: SenderEmailAddressSettings): Promise<void> {
    this.selectedSenderEmailAddressSettingsId = selectedItem.id;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Advanced::DefaultReplyAddress': typeof DefaultReplyAddress;
  }
}
