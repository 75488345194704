/* import __COLOCATED_TEMPLATE__ from './workflows-setup-common-selector-modal.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  onClose: () => void;
  modalTitleKey: string;
  selectedCategory: string;
  selectCategory: (category: string) => void;
  categories: any[];
  groupPrefix: 'template-group-' | 'trigger-context-';
  groupTitlePrefix: string;
  titleTranslationKey?: string;
  showBackButton?: boolean;
  onBack?: () => void;
}

interface Signature {
  Args: Args;
  Blocks: {
    content: [];
    headerActions?: [];
    customTopCategories?: [];
    customBottomCategories?: [];
  };
}

const WorkflowsSetupCommonSelectorModal = templateOnlyComponent<Signature>();
export default WorkflowsSetupCommonSelectorModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::NewContentModal::Workflows::WorkflowsSetupCommonSelectorModal': typeof WorkflowsSetupCommonSelectorModal;
  }
}
