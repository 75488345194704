/* import __COLOCATED_TEMPLATE__ from './step.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import templateOnlyComponent from '@ember/component/template-only';

export type CtaType = 'primary' | 'secondary';

interface Signature {
  Args: {
    name: string;
    title: string;
    description: string;
    stepNumber?: number;
    stepCompleted: boolean;
    ctaLabel?: string;
    ctaType?: CtaType;
    ctaLink: string;
    ctaOnClick?: () => void;
    ctaDisabled?: boolean;
  };
}

const StepComponent = templateOnlyComponent<Signature>();

export default StepComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::ConnectEmail::Step': typeof StepComponent;
    'new-settings/channels/email/connect-email/step': typeof StepComponent;
  }
}
