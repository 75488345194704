/* import __COLOCATED_TEMPLATE__ from './admin.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

export interface Args {
  integrationCode: string;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class Admin extends Component<Signature> {
  @service declare intl: IntlService;

  inviteAdminUrl = 'apps.app.settings.workspace.teammates';

  translation = (path: string, options = {}) => this.intl.t(this.translationKey(path), options);
  private translationKey = (path: string) =>
    `new-settings.data.imports-exports.import-zendesk.config-import-page.sections.ticket.admin.${path}`;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk::ConfigImport::Sections::Ticket::Admin': typeof Admin;
    'new-settings/data/imports-exports/import-zendesk/config-import/sections/ticket/admin': typeof Admin;
  }
}
