/* import __COLOCATED_TEMPLATE__ from './styles.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import intercomMessengerColors from '@intercom/intercom-messenger-colors';

const conversationBaseColor = '#000';
const conversationErrorColor = '#f00';
const conversationColors = intercomMessengerColors({
  primaryColor: conversationBaseColor,
  secondaryColor: conversationBaseColor, // Doesn't influence the QR colors
  darkenAmount: 10,
});

const conversationErrorColors = intercomMessengerColors({
  primaryColor: conversationErrorColor,
  secondaryColor: conversationErrorColor, // Doesn't influence the QR colors
  darkenAmount: 10,
});

export default Component.extend({
  appColor: {
    primary: conversationBaseColor,
  },
  suggestionColor: {
    text_color: conversationColors.quick_reply_text_color,
    background_color: conversationColors.quick_reply_background_color,
  },
  suggestionErrorColor: {
    text_color: conversationErrorColors.quick_reply_text_color,
    background_color: conversationErrorColors.quick_reply_background_color,
  },
  cardBorderTopColor: conversationColors.card_border_top_color,
});
