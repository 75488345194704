/* import __COLOCATED_TEMPLATE__ from './events.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

interface Args {
  eventSettings: any;
  eventTrackers: any;
}

export default class EventsComponent extends Component<Args> {
  @service declare appService: any;
  @service declare attributeService: any;

  get app() {
    return this.appService.app;
  }

  get activeEvents() {
    return this.attributeService.attributes.filter((attribute: any) => attribute.isUserEvent);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::People::Events': typeof EventsComponent;
  }
}
