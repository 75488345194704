/* import __COLOCATED_TEMPLATE__ from './general-links-accordion.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';
import { postRequest, putRequest, request, deleteRequest } from 'embercom/lib/inbox/requests';
import fullRelativeTimeAgo from 'embercom/lib/full-relative-time-ago';

interface Args {
  attachmentSettings: any;
  accordion: any;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

interface SettingsPayload {
  app_id: string;
  malicious_enabled: boolean;
  trusted_enabled: boolean;
}

interface SettingsResponse {
  malicious_enabled: boolean;
  malicious_last_updated_at: string;
  malicious_last_updated_by_admin_id: number;
  trusted_enabled: boolean;
  trusted_last_updated_at: string;
  trusted_last_updated_by_admin_id: number;
}

interface ErrorResponse {
  error: string;
}

interface SettingsWithAdmins extends SettingsResponse {
  malicious_admin: any;
  trusted_admin: any;
}

interface LinkPolicyPayload {
  id?: number;
  app_id: string;
  policy_type: string;
  policy_action: string;
  item: string;
}

type LinkPoliciesResponse = LinkPolicy[];

interface LinkPolicy {
  id: number;
  policy_type: string;
  policy_action: string;
  item: string;
  created_at: string;
  admin_id: number;
  admin: any;
  is_default: boolean;
}

type LinkWarningMode = 'external' | 'malicious';
type PolicyModalMode = 'create' | 'edit';

export default class GeneralLinksAccordion extends Component<Signature> {
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;

  @tracked useExternalLinkWarnings = true;
  @tracked useMaliciousLinkWarnings = true;
  @tracked showDisableLinkWarningModal = false;
  @tracked showPolicyModal = false;
  @tracked showDeletePolicyModal = false;
  @tracked isLinkSettingsLoading = true;
  @tracked isLinkPoliciesLoading = true;
  @tracked settingsResponse: SettingsResponse | null = null;
  @tracked settingsWithAdmins: SettingsWithAdmins | null = null;
  @tracked policyToBeDeleted: LinkPolicy | null = null;
  @tracked policyToBeEdited: LinkPolicy | null = null;
  @tracked policyModalMode: PolicyModalMode = 'create';
  @tracked policyTypeOptionSelected = 'domain';
  @tracked policyActionOptionSelected = 'blocked';
  @tracked policyValueInput = '';
  @tracked policyValueInputIsValid = true;
  @tracked linkWarningDisableModalMode: LinkWarningMode = 'external';
  @tracked allLinkPolicies: LinkPolicy[] = [];
  @tracked policyTableCanLoadMore = false;
  @tracked policyTablePageSize = 6;
  @tracked shownLinkPolicies: LinkPolicy[] = [];
  @tracked isLoadingMore = false;
  @tracked showDefaultPolicies = true;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.loadTeammateLinkSettings();
    this.loadTeammateLinkPolicies();
  }

  get isSaveSettingsTaskRunning() {
    return taskFor(this.saveSettingsTask).isRunning;
  }

  get isCreateOrUpdatePolicyTaskRunning() {
    return taskFor(this.createPolicyTask).isRunning || taskFor(this.updatePolicyTask).isRunning;
  }

  get policyValuePlaceholder() {
    switch (this.policyTypeOptionSelected) {
      case 'domain':
        return '*.example.com';
      case 'url':
        return 'https://www.example.com/link';
      default:
        return '*.example.com';
    }
  }

  get policyInputValidationFailMessage() {
    return this.policyTypeOptionSelected === 'domain'
      ? this.intl.t('apps.app.settings.attachment-settings.policy-modal-validation-fail-domain')
      : this.intl.t('apps.app.settings.attachment-settings.policy-modal-validation-fail-url');
  }

  get policyTypeOptions() {
    return [
      {
        text: this.intl.t('apps.app.settings.attachment-settings.policy-type-domain'),
        value: 'domain',
        icon: 'globe',
        description: this.intl.t(
          'apps.app.settings.attachment-settings.policy-type-domain-option-description',
        ),
      },
      {
        text: this.intl.t('apps.app.settings.attachment-settings.policy-type-url'),
        value: 'url',
        icon: 'link',
        description: this.intl.t(
          'apps.app.settings.attachment-settings.policy-type-url-option-description',
        ),
      },
    ];
  }

  private getFilteredPolicies() {
    return this.showDefaultPolicies
      ? this.allLinkPolicies
      : this.allLinkPolicies.filter((policy) => !policy.is_default);
  }

  private loadPolicyFirstPage(pageSize: number) {
    let teammatePolicies = this.getFilteredPolicies();
    this.shownLinkPolicies = teammatePolicies.slice(0, pageSize);
    this.policyTableCanLoadMore = teammatePolicies.length > pageSize;
  }

  @action
  loadMorePolicies() {
    this.isLoadingMore = true;
    let teammatePolicies = this.getFilteredPolicies();
    this.shownLinkPolicies = teammatePolicies.slice(
      0,
      this.shownLinkPolicies.length + this.policyTablePageSize,
    );
    this.policyTableCanLoadMore = this.shownLinkPolicies.length < teammatePolicies.length;
    this.isLoadingMore = false;
  }

  @action
  toggleUseExternalLinkWarnings() {
    if (this.useExternalLinkWarnings) {
      this.openDisableLinkWarningModal('external');
    } else {
      this.enableUseTrustedDomainsForUrlVerification();
    }
  }

  @action
  toggleUseMaliciousLinkWarnings() {
    if (this.useMaliciousLinkWarnings) {
      this.openDisableLinkWarningModal('malicious');
    } else {
      this.enableUseMaliciousDomainsForUrlScanning();
    }
  }

  @action
  openDisableLinkWarningModal(mode: LinkWarningMode) {
    this.linkWarningDisableModalMode = mode;
    this.showDisableLinkWarningModal = true;
  }

  @action
  enableUseTrustedDomainsForUrlVerification() {
    this.useExternalLinkWarnings = true;
    taskFor(this.saveSettingsTask).perform();
  }

  @action
  enableUseMaliciousDomainsForUrlScanning() {
    this.useMaliciousLinkWarnings = true;
    taskFor(this.saveSettingsTask).perform();
  }

  @action
  closeLinkModal() {
    this.showDisableLinkWarningModal = false;
  }

  @action
  disableDomainsFeature() {
    switch (this.linkWarningDisableModalMode) {
      case 'external':
        this.args.attachmentSettings.set('useTrustedDomainsForUrlVerification', false);
        this.useExternalLinkWarnings = false;
        break;
      case 'malicious':
        this.args.attachmentSettings.set('useMaliciousDomainsForUrlScanning', false);
        this.useMaliciousLinkWarnings = false;
        break;
    }
    taskFor(this.saveSettingsTask).perform();
    this.closeLinkModal();
  }

  @action
  showDeletePolicyConfirmation(policy: LinkPolicy) {
    this.policyToBeDeleted = policy;
    this.showDeletePolicyModal = true;
  }

  @action
  showEditPolicyModal(policy: LinkPolicy) {
    this.policyToBeEdited = policy;
    this.policyValueInput = policy.item;
    this.policyTypeOptionSelected = policy.policy_type;
    this.policyActionOptionSelected = policy.policy_action;
    this.showPolicyModal = true;
  }

  @action closePolicyModal() {
    this.showPolicyModal = false;
    this.policyToBeEdited = null;
    this.policyValueInput = '';
    this.policyTypeOptionSelected = 'domain';
    this.policyActionOptionSelected = 'blocked';
    this.policyValueInputIsValid = true;
  }

  @action createOrUpdatePolicy() {
    this.validatePolicyValueInput();
    if (this.policyValueInputIsValid) {
      if (this.policyToBeEdited) {
        taskFor(this.updatePolicyTask).perform();
      } else {
        taskFor(this.createPolicyTask).perform();
      }
    }
  }

  get policyValidationPattern() {
    switch (this.policyTypeOptionSelected) {
      case 'domain':
        return /^(localhost|(\*\.)?([a-z0-9]+(-[a-z0-9]+)*\.)+([a-z]{2,}))$/i;
      case 'url':
        return /^(https?:\/\/)([a-z0-9]+(-[a-z0-9]+)*\.)+([a-z]{2,})(\/[a-zA-Z0-9\-_\/]*)?(\?[a-zA-Z0-9\-_=&]*)?$/i;
      default:
        return /.*/i;
    }
  }

  @action
  validatePolicyValueInput() {
    this.policyValueInputIsValid = this.policyValidationPattern.test(this.policyValueInput);
  }

  @action toggleShowDefaultPolicies() {
    this.showDefaultPolicies = !this.showDefaultPolicies;
    this.loadPolicyFirstPage(this.policyTablePageSize);
  }

  @action getRelativeTime(dateString: string) {
    return fullRelativeTimeAgo(dateString);
  }

  async loadTeammateLinkSettings() {
    try {
      let response = await request(
        `/ember/teammate_link_domain_settings?app_id=${this.appService.app.id}`,
      );
      this.isLinkSettingsLoading = false;
      this.settingsResponse = await response.json();
      this.loadSettingsAdmins();
      this.useExternalLinkWarnings = this.settingsResponse!.trusted_enabled;
      this.useMaliciousLinkWarnings = this.settingsResponse!.malicious_enabled;
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('apps.app.settings.attachment-settings.load-failed'),
      });
    }
  }

  async loadTeammateLinkPolicies() {
    try {
      let response = await request(
        `/ember/teammate_link_domain_policies?app_id=${this.appService.app.id}`,
      );
      this.isLinkPoliciesLoading = false;
      let policiesResponse: LinkPoliciesResponse = await response.json();
      this.allLinkPolicies = policiesResponse;
      this.loadPolicyAdmins();
      this.formatPolicyDates();
      this.loadPolicyFirstPage(this.policyTablePageSize);
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('apps.app.settings.attachment-settings.load-failed'),
      });
    }
  }

  @dropTask
  *saveSettingsTask() {
    try {
      let payload: SettingsPayload = {
        app_id: this.appService.app.id,
        trusted_enabled: this.useExternalLinkWarnings,
        malicious_enabled: this.useMaliciousLinkWarnings,
      };
      let response: Response = yield putRequest(
        `/ember/teammate_link_domain_settings/update`,
        payload,
      );
      let settingsResponse: SettingsResponse = yield response.json();
      this.settingsResponse = settingsResponse;
      this.loadSettingsAdmins();
      this.notificationsService.notifyConfirmation(
        this.intl.t('apps.app.settings.attachment-settings.save-successful'),
      );
    } catch (error) {
      this.useExternalLinkWarnings = this.settingsResponse!.trusted_enabled;
      this.useMaliciousLinkWarnings = this.settingsResponse!.malicious_enabled;
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('apps.app.settings.attachment-settings.save-failed'),
      });
    }
    return;
  }

  @dropTask
  *createPolicyTask() {
    try {
      let payload: LinkPolicyPayload = {
        app_id: this.appService.app.id,
        policy_action: this.policyActionOptionSelected,
        policy_type: this.policyTypeOptionSelected,
        item: this.policyValueInput,
      };

      let response: Response = yield postRequest(`/ember/teammate_link_domain_policies`, payload);

      let policyResponse: LinkPoliciesResponse = yield response.json();
      this.notificationsService.notifyConfirmation(
        this.intl.t('apps.app.settings.attachment-settings.policy-save-successful'),
      );
      this.allLinkPolicies = policyResponse;
      this.loadPolicyAdmins();
      this.formatPolicyDates();
      this.loadPolicyFirstPage(Math.max(this.policyTablePageSize, this.shownLinkPolicies.length));
      this.showPolicyModal = false;
      this.policyValueInput = '';
      this.policyActionOptionSelected = 'blocked';
      this.policyTypeOptionSelected = 'domain';
    } catch (error) {
      let message;
      try {
        if (error.response?.json) {
          let error_response: ErrorResponse = yield error.response.json();
          let error_code = error_response.error;
          message = this.getErrorMessage(error_code);
        }
      } catch {
        message = this.intl.t('apps.app.settings.attachment-settings.policy-save-failed');
      }

      this.notificationsService.notifyResponseError(error, {
        default: message,
      });
    }
  }

  @dropTask
  *updatePolicyTask() {
    try {
      let payload: LinkPolicyPayload = {
        app_id: this.appService.app.id,
        id: this.policyToBeEdited?.id,
        policy_action: this.policyActionOptionSelected,
        policy_type: this.policyTypeOptionSelected,
        item: this.policyValueInput,
      };

      let response: Response = yield putRequest(
        `/ember/teammate_link_domain_policies/${this.policyToBeEdited?.id}?app_id=${this.appService.app.id}`,
        payload,
      );

      let policyResponse: LinkPoliciesResponse = yield response.json();
      this.notificationsService.notifyConfirmation(
        this.intl.t('apps.app.settings.attachment-settings.policy-save-successful'),
      );
      this.allLinkPolicies = policyResponse;
      this.loadPolicyAdmins();
      this.formatPolicyDates();
      this.loadPolicyFirstPage(Math.max(this.policyTablePageSize, this.shownLinkPolicies.length));
      this.showPolicyModal = false;
      this.policyToBeEdited = null;
      this.policyValueInput = '';
      this.policyActionOptionSelected = 'blocked';
      this.policyTypeOptionSelected = 'domain';
    } catch (error) {
      let message;
      try {
        if (error.response?.json) {
          let error_response: ErrorResponse = yield error.response.json();
          let error_code = error_response.error;
          message = this.getErrorMessage(error_code);
        }
      } catch {
        message = this.intl.t('apps.app.settings.attachment-settings.policy-save-failed');
      }
      this.notificationsService.notifyResponseError(error, {
        default: message,
      });
    }
  }

  @dropTask
  *deletePolicyTask() {
    if (this.policyToBeDeleted) {
      try {
        let response: Response = yield deleteRequest(
          `/ember/teammate_link_domain_policies/${this.policyToBeDeleted.id}?app_id=${this.appService.app.id}`,
        );

        let policyResponse: LinkPoliciesResponse = yield response.json();
        this.allLinkPolicies = policyResponse;
        this.loadPolicyAdmins();
        this.formatPolicyDates();
        this.loadPolicyFirstPage(Math.max(this.policyTablePageSize, this.shownLinkPolicies.length));
        this.policyToBeDeleted = null;
        this.showDeletePolicyModal = false;

        this.notificationsService.notifyConfirmation(
          this.intl.t('apps.app.settings.attachment-settings.policy-delete-successful'),
        );
      } catch (error) {
        let message;
        try {
          if (error.response?.json) {
            let error_response: ErrorResponse = yield error.response.json();
            let error_code = error_response.error;
            message = this.getErrorMessage(error_code);
          }
        } catch {
          message = this.intl.t('apps.app.settings.attachment-settings.policy-delete-failed');
        }
        this.notificationsService.notifyResponseError(error, {
          default: message,
        });
      }
    }
  }

  private loadPolicyAdmins() {
    let policiesWithAdmins = this.allLinkPolicies;
    for (let i = 0; i < this.allLinkPolicies.length; i++) {
      let admin_id = this.allLinkPolicies[i].admin_id;
      if (admin_id === 0) {
        policiesWithAdmins[i].admin = {
          nameOrEmail: 'Intercom',
        };
      } else {
        let admin = this.adminFromId(admin_id);
        policiesWithAdmins[i].admin = admin;
      }
    }
    this.allLinkPolicies = policiesWithAdmins;
  }

  private loadSettingsAdmins() {
    if (
      !this.settingsResponse ||
      !this.settingsResponse.malicious_last_updated_by_admin_id ||
      !this.settingsResponse.trusted_last_updated_by_admin_id
    ) {
      return;
    }

    let settingsWithAdmins = this.settingsResponse as SettingsWithAdmins;
    let malicious_admin_id = this.settingsResponse.malicious_last_updated_by_admin_id;
    let trusted_admin_id = this.settingsResponse.trusted_last_updated_by_admin_id;
    let malicious_admin = this.adminFromId(malicious_admin_id);
    let trusted_admin = this.adminFromId(trusted_admin_id);
    settingsWithAdmins.malicious_admin = malicious_admin;
    settingsWithAdmins.trusted_admin = trusted_admin;
    this.settingsWithAdmins = settingsWithAdmins;
  }

  private adminFromId(admin_id: number) {
    return this.appService.app.admins.findBy('id', admin_id.toString());
  }

  private formatPolicyDates() {
    for (let policy of this.allLinkPolicies) {
      if (policy.created_at === '-') {
        // eslint-disable-next-line no-continue
        continue;
      }
      let date = new Date(policy.created_at);
      let day = date.getDate().toString().padStart(2, '0');
      let month = (date.getMonth() + 1).toString().padStart(2, '0');
      let year = date.getFullYear().toString().slice(-2);
      policy.created_at = `${day}/${month}/${year}`;
    }
  }

  private getErrorMessage(error_code: string) {
    return this.intl.t(`apps.app.settings.attachment-settings.error-${error_code}`);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Security::GeneralLinksAccordion': typeof GeneralLinksAccordion;
    'new-settings/workspace/security/general-links-accordion': typeof GeneralLinksAccordion;
  }
}
