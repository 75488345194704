/* import __COLOCATED_TEMPLATE__ from './debug-toggle.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import type Router from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type DetectIntercomradesDomainService from 'embercom/services/detect-intercomrades-domain-service';

export interface Args {
  enabled: boolean;
  toggle: () => void;
}

export default class DebugToggle extends Component<Args> {
  @service declare detectIntercomradesDomainService: DetectIntercomradesDomainService;
  @service declare router: Router;

  get isDebuggingAllowed() {
    return (
      this.detectIntercomradesDomainService.isIntercomradesDomain &&
      this.router.currentRoute.queryParams.debug
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::DebugToggle': typeof DebugToggle;
  }
}
