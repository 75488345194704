/* import __COLOCATED_TEMPLATE__ from './business-profile.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type WhatsappIntegration from 'embercom/models/whatsapp/integration';

interface BusinessProfileArgs {
  setBusinessProfile: (integration: WhatsappIntegration) => void;
  whatsappIntegrations: WhatsappIntegration[];
}

export default class BusinessProfile extends Component<BusinessProfileArgs> {
  @service declare intercomEventService: $TSFixMe;

  @tracked private _selectedIntegration?: WhatsappIntegration;

  constructor(owner: unknown, args: BusinessProfileArgs) {
    super(owner, args);
    this._selectedIntegration = this.firstConnectedIntegration;
  }

  get whatsappIntegrations() {
    return this.args.whatsappIntegrations;
  }

  get connectedWhatsappIntegrations() {
    return this.whatsappIntegrations.filter((integration) => integration.isPhoneNumberConnected);
  }

  get firstConnectedIntegration() {
    return this.connectedWhatsappIntegrations[0];
  }
  get selectedIntegration() {
    return (
      this.whatsappIntegrations.findBy('id', this._selectedIntegration?.id) ??
      this.firstConnectedIntegration
    );
  }

  set selectedIntegration(value: WhatsappIntegration | undefined) {
    this._selectedIntegration = value;
  }

  get integrationsDropdownData() {
    let dropdownItems = this.connectedWhatsappIntegrations.map((integration) => {
      return {
        text: integration.displayString,
        value: integration,
        isSelected: this.selectedIntegration?.id === integration.id,
      };
    });

    return [
      {
        hasItemsMarkableAsSelected: true,
        items: dropdownItems,
      },
    ];
  }

  get hasMultipleConnectedIntegrations() {
    return this.connectedWhatsappIntegrations.length > 1;
  }

  @action
  selectIntegration(integration?: WhatsappIntegration) {
    this.selectedIntegration = integration;
  }

  @action triggerSetBusinessProfile() {
    if (this.selectedIntegration) {
      this.args.setBusinessProfile(this.selectedIntegration);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'set_business_profile_button',
        place: 'settings_channels_whatsapp',
        section: 'set_business_profile',
      });
    }
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Whatsapp::BusinessProfile': typeof BusinessProfile;
    'new-settings/channels/whatsapp/business-profile': typeof BusinessProfile;
  }
}
