/* import __COLOCATED_TEMPLATE__ from './object-type-filter.hbs'; */
/* RESPONSIBLE TEAM: team-actions */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { ZendeskObjectTypes } from 'embercom/components/settings/data-import/migrate-from-zendesk';

interface Args {
  selectedObjectTypes: ZendeskObjectTypes[];
  onChange: (objectType: string) => void;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class ObjectTypeFilter extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: any;

  get selectedObjectType() {
    if (this.args.selectedObjectTypes.length === 1) {
      return this.args.selectedObjectTypes.firstObject;
    }

    return '';
  }

  get title() {
    let prefix = this.translation('object-type-filter.object-type-label');
    let text = this.objectTypes.find((i) => i.value === this.selectedObjectType)!.text;

    return `${prefix} ${text}`;
  }

  get objectTypes() {
    let any = {
      text: this.translation('object-type-filter.any'),
      value: '',
    };
    let options = Object.values(ZendeskObjectTypes).map((key) => ({
      text: this.translation(`object-types-mapping.${key}`),
      value: key,
    }));

    return [any, ...options];
  }

  translation = (path: string) => this.intl.t(this.translationKey(path));
  private translationKey = (path: string) =>
    `new-settings.data.imports-exports.import-zendesk.migration-history.${path}`;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk::MigrationHistory::ObjectTypeFilter': typeof ObjectTypeFilter;
    'new-settings/data/imports-exports/import-zendesk/migration-history/object-type-filter': typeof ObjectTypeFilter;
  }
}
