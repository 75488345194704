/* import __COLOCATED_TEMPLATE__ from './addresses-table.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import type DomainService from 'embercom/services/domain-service';
import { type Domain } from 'embercom/services/domain-service';
import type EntriConnectService from 'embercom/services/entri-connect-service';

interface Args {
  domains: Domain[];
  changeStep: (step: string) => void;
  setEmail: (email: string) => Promise<void>;
}

interface Signature {
  Args: Args;
}

export default class AddressesTable extends Component<Signature> {
  @service declare domainService: DomainService;
  @service declare entriConnectService: EntriConnectService;
  @service declare intercomEventService: any;
  @service declare appService: $TSFixMe;
  @tracked domainCanBeAutoConfigured = false;

  get app() {
    return this.appService.app;
  }

  get canUseAutomaticStep() {
    return this.app.canUseEntriConnect && this.domainCanBeAutoConfigured;
  }

  get selectedDomain() {
    return this.domainService.selectedDomain;
  }

  get dkimSettings() {
    return this.selectedDomain?.dkimSettings;
  }

  get customBounceSettings() {
    return this.selectedDomain?.bounceSettings;
  }

  @action
  async navigateToSetupDomain(domainName: string) {
    this.domainService.setSelectedDomainWithDomainName(domainName);
    await this.checkDomainAndLoadEntri();
    if (this.domainService.selectedDomain) {
      if (this.canUseAutomaticStep) {
        this.args.changeStep('setup-domain');
      } else {
        this.trackEvent('navigate_to_setup_domain_manually');
        this.args.changeStep('setup-domain-manually');
      }
    }
  }

  @action
  async openSetupSidebar(emailAddress: string) {
    await this.args.setEmail(emailAddress);
    this.args.changeStep('edit-address');
    this.trackEvent('edit_button');
  }

  @action
  startSetup(object: string) {
    this.args.changeStep('add-address');
    this.trackEvent(object);
  }

  @action
  async checkDomainAndLoadEntri(): Promise<void> {
    this.domainCanBeAutoConfigured = await taskFor(this.entriConnectService.checkDomain).perform(
      Number(this.dkimSettings?.id) ?? 0,
      Number(this.customBounceSettings?.id) ?? 0,
    );
    if (this.domainCanBeAutoConfigured) {
      taskFor(this.entriConnectService.loadEntriLibrary).perform();
    }
  }

  @action
  trackEvent(object: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
      place: 'addresses-table',
      context: 'connect-email-tab',
    });
  }

  canShowAuthenticationInstructions(domain: Domain) {
    return (
      domain.dkimSettings?.hasVerifiedAdminEmails ||
      (domain.addresses && domain.addresses.some((address: any) => address.verified))
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::ConnectEmail::AddressesTable': typeof AddressesTable;
  }
}
