/* import __COLOCATED_TEMPLATE__ from './messenger-transition.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import { restartableTask } from 'ember-concurrency-decorators';
import type WhatsappSettings from 'embercom/models/whatsapp/settings';
import type WhatsappIntegration from 'embercom/models/whatsapp/integration';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface MessengerTransitionArgs {
  settings: WhatsappSettings;
  whatsappIntegrations: WhatsappIntegration[];
}

export default class MessengerTransition extends Component<MessengerTransitionArgs> {
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;

  @tracked private _selectedIntegration?: WhatsappIntegration;

  constructor(owner: unknown, args: MessengerTransitionArgs) {
    super(owner, args);
    this._selectedIntegration = this.firstConnectedIntegration;
  }

  get whatsappIntegrations() {
    return this.args.whatsappIntegrations;
  }

  get connectedWhatsappIntegrations() {
    return this.whatsappIntegrations.filter((integration) => integration.isPhoneNumberConnected);
  }

  get firstConnectedIntegration() {
    return this.connectedWhatsappIntegrations[0];
  }

  get selectedIntegration() {
    return (
      this.whatsappIntegrations.findBy('id', this._selectedIntegration?.id) ??
      this.firstConnectedIntegration
    );
  }

  set selectedIntegration(value: WhatsappIntegration | undefined) {
    this._selectedIntegration = value;
  }

  get integrationsDropdownData() {
    let dropdownItems = this.connectedWhatsappIntegrations.map((integration) => {
      return {
        text: integration.displayString,
        value: integration,
        isSelected: this.selectedIntegration?.id === integration.id,
      };
    });

    return [
      {
        hasItemsMarkableAsSelected: true,
        items: dropdownItems,
      },
    ];
  }

  get hasMultipleConnectedIntegrations() {
    return this.connectedWhatsappIntegrations.length > 1;
  }

  get whatsappSettings() {
    return this.args.settings;
  }

  @action
  selectIntegration(integration?: WhatsappIntegration) {
    this.selectedIntegration = integration;
  }

  @restartableTask
  *toggleMessengerIntegration() {
    try {
      this.whatsappSettings.toggleProperty('messengerIntegration');
      if (this.messengerIntegrationOn) {
        this.whatsappSettings.set('messengerIntegrationId', this.selectedIntegration?.id);
      } else {
        this.whatsappSettings.set('messengerIntegrationId', null);
      }
      yield this.whatsappSettings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('new-settings.channels.whatsapp.messenger-transition.save-success'),
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'switch_messenger_to_whatsapp_button',
        place: 'settings_channels_whatsapp',
        section: 'switch_messenger_whatsapp',
      });
    } catch (_) {
      this.notificationsService.notifyError(
        this.intl.t('new-settings.channels.whatsapp.messenger-transition.save-error'),
      );
    }
  }

  @restartableTask
  *changeMessengerIntegration() {
    if (this.messengerIntegrationOn) {
      this.whatsappSettings.set('messengerIntegrationId', this.selectedIntegration?.id);
    }
    yield this.whatsappSettings.save();
  }

  get messengerIntegrationOn() {
    return this.whatsappSettings.messengerIntegration;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Whatsapp::MessengerTransition': typeof MessengerTransition;
    'new-settings/channels/whatsapp/messenger-transition': typeof MessengerTransition;
  }
}
