/* import __COLOCATED_TEMPLATE__ from './new-workflow-wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Template from 'embercom/models/template';

import { TEMPLATE_GROUPS } from 'embercom/lib/operator/custom-bots/constants';
import type CustomBotConfig from 'embercom/objects/operator/configuration/custom-bot';

type Args = {
  onClose: () => void;
  triggers: (CustomBotConfig & { onSelect: () => void })[];
  templates: Template[];
  selectedTemplate: Template | null;
  selectedTemplateContentObject: any;
  selectTemplate: (template: Template) => void;
  createWorkflow: () => void;
  templateSelectorMode?: string;
  disableTransitionBack?: boolean;
  displayInstallAppPackageModal?: (appPackageCode: string) => void;
  showSimilarTemplates?: boolean;
  showTriggersModal?: boolean;
  triggersModalBackButtonVisible?: boolean;
  setTriggersBackButtonVisible: (backButtonVisible: boolean) => void;
};

export default class NewWorkflowWrapper extends Component<Args> {
  @service declare appService: any;
  @service intercomEventService: any;
  @tracked activeModalView = this.activeModalViewFromParams;

  get activeModalViewFromParams() {
    if (this.args.showTriggersModal) {
      return 'triggerSelector';
    } else if (this.args.selectedTemplate) {
      return 'templatePreview';
    } else {
      return 'templateSelector';
    }
  }

  get app() {
    return this.appService.app;
  }

  get areAllTriggersPaywalled() {
    return this.args.triggers.every(
      (trigger) => !this.app.canUseFeature(trigger.requiredBillingFeature),
    );
  }

  get areAllTemplatesPaywalled() {
    let templateGroupIds = TEMPLATE_GROUPS.map((group) => group.id);
    return this.args.templates
      .filter((template) => templateGroupIds.includes(template.objective))
      .every(
        (template) =>
          !this.app.canUseFeature(
            template.ruleset.rulesetLinks.firstObject.object.config.requiredBillingFeature,
          ),
      );
  }

  @action
  transitionToPreview() {
    this.activeModalView = 'templatePreview';
  }

  @action
  recordAnalyticsEvent(actionLabel: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: actionLabel,
      object: 'new_workflow_modal',
      place: this.activeModalView,
      context: 'operator',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::NewContentModal::Workflows::NewWorkflowWrapper': typeof NewWorkflowWrapper;
  }
}
