/* import __COLOCATED_TEMPLATE__ from './domain-sidebar.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { type Domain } from 'embercom/components/new-settings/channels/email/addresses/tab';

interface Args {
  onClose: () => void;
  domain: Domain;
}

export default class DomainSidebar extends Component<Args> {
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;
  @tracked isVerifying = false;
  @tracked showVerificationError = false;
  @tracked openSectionId: string | null = null;

  @action onOpenSectionChange(openSectionId: string) {
    this.openSectionId = openSectionId;
  }

  get hasVerifiedEmailAddresses() {
    return (
      this.domain.dkimSettings?.hasVerifiedAdminEmails ||
      (this.domain.addresses && this.domain.addresses.some((address: any) => address.verified))
    );
  }

  get hasCustomAddresses() {
    return this.domain.addresses && this.domain.addresses.length > 0;
  }

  get canShowAuthenticationInstructions() {
    return this.hasVerifiedEmailAddresses || this.domain.isAuthenticated;
  }

  get domainSettingsInstructions() {
    let message = [];

    message.push(
      this.intl.t('new-settings.channels.email.addresses.domain-sidebar.instructions-text', {
        domainName: this.domain.bounceSettings.rootDomain,
        htmlSafe: true,
      }),
    );

    message.push(
      this.intl.t('new-settings.channels.email.addresses.domain-sidebar.cname-host-value-record', {
        host: `${this.domain.dkimSettings.domainForCustomerDnsRecord}.`,
        value: `${this.domain.dkimSettings.domainForIntercomDnsRecord}.`,
        htmlSafe: true,
      }),
    );

    message.push(
      this.intl.t('new-settings.channels.email.addresses.domain-sidebar.cname-host-value-record', {
        host: `${this.domain.bounceSettings.host}.`,
        value: `${this.domain.bounceSettings.value}.`,
        htmlSafe: true,
      }),
    );

    message.push(
      this.intl.t('new-settings.channels.email.addresses.domain-sidebar.txt-host-value-record', {
        host: `_dmarc.${this.domain.dkimSettings.domain}.`,
        value: `v=DMARC1; p=none`,
        htmlSafe: false,
      }),
    );

    message.push(this.intl.t('new-settings.channels.email.addresses.domain-sidebar.can-you-help'));

    return message;
  }

  get dominSettingsInstructionsHtml() {
    return this.domainSettingsInstructions.map((instruction) =>
      instruction.toString().replace(/\n/g, '<br/>'),
    );
  }

  get domainSettingsInstructionsText() {
    return this.domainSettingsInstructions.join('\n');
  }

  get onClose() {
    return this.args.onClose;
  }

  get domain() {
    return this.args.domain;
  }

  get domainAuthenticationStatusText() {
    return this.domain.isAuthenticated
      ? this.intl.t('new-settings.channels.email.addresses.domain-sidebar.authenticated')
      : this.intl.t('new-settings.channels.email.addresses.domain-sidebar.unauthenticated');
  }

  get domainAuthenticationStatusColour() {
    return this.domain.isAuthenticated ? 'green' : 'yellow-light';
  }

  get domainVerificationStatusIcon() {
    return this.domain.isAuthenticated ? '' : 'alert-circle';
  }

  @action
  async verifyDomain() {
    try {
      this.isVerifying = true;
      await this.domain.dkimSettings.verify();
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'new-settings.channels.email.addresses.domain-sidebar.domain-verification-success',
        ),
      );
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t(
          'new-settings.channels.email.addresses.domain-sidebar.domain-verification-failed',
        ),
      );
    } finally {
      this.isVerifying = false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Addresses::DomainSidebar': typeof DomainSidebar;
  }
}
