/* import __COLOCATED_TEMPLATE__ from './business-profile.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'embercom/services/intl';
import type WhatsappIntegration from 'embercom/models/whatsapp/integration';
import { allVerticals } from 'embercom/models/data/whatsapp/whatsapp-constants';

interface SidebarBusinessProfileArgs {
  whatsappIntegration: WhatsappIntegration;
}

export default class SidebarBusinessProfile extends Component<SidebarBusinessProfileArgs> {
  @service declare intl: IntlService;

  get allVerticals() {
    return allVerticals.map((vertical) => {
      return {
        text: this.intl.t(vertical.text),
        value: vertical.value,
      };
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Whatsapp::Sidebar::BusinessProfile': typeof SidebarBusinessProfile;
    'new-settings/channels/whatsapp/sidebar/business-profile': typeof SidebarBusinessProfile;
  }
}
