/* import __COLOCATED_TEMPLATE__ from './attributes-mapping-table.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { type MappingVisibility } from 'embercom/models/crm/attribute-mapping';
import type AttributeMapping from 'embercom/models/crm/attribute-mapping';
import { type SourceAttributeType } from 'embercom/services/data/import-configuration-service';
import type ImportConfigurationService from 'embercom/services/data/import-configuration-service';
import type { default as CrmAttribute } from 'embercom/models/crm/attribute';

export interface Args {
  integrationCode: string;
  sectionName: string;
  mappingVisibility?: MappingVisibility;
  attributeType?: SourceAttributeType;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class AttributesMappingTable extends Component<Signature> {
  @service declare intl: IntlService;
  @service('data/import-configuration-service')
  declare importConfigurationService: ImportConfigurationService;

  get mappingVisibility() {
    return this.args.mappingVisibility || 'public';
  }

  get sectionData() {
    if (this.args.attributeType !== undefined) {
      return this.importConfigurationService.listMappings(
        this.args.sectionName,
        this.mappingVisibility,
        this.args.attributeType,
      );
    }
    return this.importConfigurationService.listMappings(
      this.args.sectionName,
      this.mappingVisibility,
    );
  }

  sourceAttributeLabel = (attribute: CrmAttribute | undefined, fallback: string) => {
    if (attribute === undefined) {
      return fallback;
    }
    return attribute.label || attribute.path;
  };

  sourceAttribute = (mapping: AttributeMapping) => {
    return this.importConfigurationService.matchingSourceAttribute(mapping);
  };

  destinationAttributeLabel = (mapping: AttributeMapping) => {
    let attribute = this.importConfigurationService.matchingDestinationAttribute(mapping);
    if (attribute === undefined) {
      return mapping.destinationAttributePath;
    }
    return attribute.name || attribute.identifier;
  };

  readOnlyMapping(mapping: AttributeMapping) {
    return mapping.status === 'non-mappable' || !mapping.editable;
  }

  isTicketStatusMapping(mapping: AttributeMapping) {
    return mapping.sourceObjectType === 'ticket' && mapping.sourceAttributePath === 'status';
  }

  translation = (path: string) => this.intl.t(this.translationKey(path));
  private translationKey = (path: string) =>
    `new-settings.data.imports-exports.import-zendesk.config-import-page.attributes-mapping-table.${path}`;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk::ConfigImport::Sections::Lib::AttributesMappingTable': typeof AttributesMappingTable;
    'new-settings/data/imports-exports/import-zendesk/config-import/sections/lib/attributes-mapping-table': typeof AttributesMappingTable;
  }
}
