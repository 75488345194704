/* import __COLOCATED_TEMPLATE__ from './attributes.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import EmberObject, { action } from '@ember/object';
import { dropTask } from 'ember-concurrency-decorators';
import { put } from 'embercom/lib/ajax';
import type IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import NameAndDescriptionCellCompoonent from 'embercom/components/table-cells/attributes/name-and-description-cell';
import FormatAndOptionsCellComponent from 'embercom/components/table-cells/attributes/format-and-options-cell';
import CreatedAtCellComponent from 'embercom/components/table-cells/attributes/created-at-cell';
import EditButtonCellComponent from 'embercom/components/table-cells/attributes/edit-button-cell';

interface Signature {
  Element: never;
  Args: Args;
}

interface Args {
  model: any;
}

export default class AttributesComponent extends Component<Signature> {
  @service declare appService: any;
  @service declare intercomEventService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare attributeService: any;
  @service declare modalService: any;

  @tracked filterSearchTerm = '';

  get columns() {
    return A([
      EmberObject.create({
        label: this.intl.t('settings.people-data.attribute-name'),
        canExpandToFit: true,
        component: NameAndDescriptionCellCompoonent,
        sortable: true,
        isSortColumn: false,
        sortedAscending: true,
        propertyName: 'attribute.name',
      }),
      EmberObject.create({
        label: this.intl.t('settings.people-data.format'),
        component: FormatAndOptionsCellComponent,
        canExpandToFit: false,
      }),
      EmberObject.create({
        label: this.intl.t('settings.people-data.created'),
        component: CreatedAtCellComponent,
        sortable: true,
        isSortColumn: false,
        sortedAscending: false,
        propertyName: 'attribute.created_at',
      }),
      EmberObject.create({
        component: EditButtonCellComponent,
      }),
    ]);
  }

  get currentSortColumn(): any {
    return this.columns.find((column: any) => column.isSortColumn);
  }

  get rows() {
    return this.sortedAttributes.concat(this.archivedAttributes).uniqBy('attribute.identifier');
  }

  get filteredRows() {
    return this.rows.filter((row: any) => this.rowMatchesFilterSearchTerm(row));
  }

  get sortedAttributes() {
    if (this.sortDefinition) {
      return this.attributesSortedBySortDefinition;
    } else {
      return this.attributesWithDefaultSorting;
    }
  }

  get sortDefinition() {
    let sortColumn = this.currentSortColumn;

    if (!sortColumn) {
      return null;
    }

    return sortColumn.sortedAscending
      ? [sortColumn.propertyName]
      : [`${sortColumn.propertyName}:desc`];
  }

  get attributesSortedBySortDefinition() {
    return this.attributes.sortBy(this.sortDefinition);
  }

  get attributesWithDefaultSorting() {
    return this.attributes.sort((a1: any, a2: any) => {
      if (a1.isStandard && a2.isCustomData) {
        return -1;
      } else if (a1.isCustomData && a2.isStandard) {
        return 1;
      }
      return 0;
    });
  }

  get attributes() {
    return this.attributeService.displayableCompanyAttributes;
  }

  get archivedAttributes() {
    return this.archivedAttributesWithSettings.filterBy('attribute.isCompany');
  }

  get archivedAttributesWithSettings() {
    return this.attributeService.attributesWithSettings(this.args.model.archivedAttributes);
  }

  rowMatchesFilterSearchTerm(row: any) {
    let rowAttributeName: string = row.attribute.name.toLowerCase();
    let filterSearchTerm = this.filterSearchTerm.toLowerCase();
    return rowAttributeName.includes(filterSearchTerm);
  }

  @action
  openDetailsView(row: any) {
    if (row) {
      this.modalService.openModal('settings/modals/attribute-details', {
        attribute: row.attribute,
        allowArchival: row.attribute.isCustomData,
      });
    }
  }

  @action
  toggleSort(column: any) {
    let columns = this.columns;
    let currentSortColumn = this.currentSortColumn;

    if (currentSortColumn?.propertyName === column.propertyName) {
      currentSortColumn.toggleProperty('sortedAscending');
    } else {
      currentSortColumn?.set('isSortColumn', false);

      let newSortColumn: any = columns.find((c: any) => c.propertyName === column.propertyName);
      if (newSortColumn) {
        newSortColumn.isSortColumn = true;
      }
    }
  }

  @dropTask
  *toggleMessengerCdaCreation() {
    try {
      let data = {
        app_id: this.appService.app.id,
        messenger_cda_creation_enabled: !this.appService.app.hasMessengerCdaCreationEnabled,
      };
      yield put('/ember/messenger_cda_creation_settings/update_setting', data).then(() => {
        this.appService.app.reload().then(() => {
          this.notificationsService.notifyConfirmation(
            this.intl.t('settings.people-data.secure-mode-update-success'),
          );
        });
      });
    } catch (e) {
      this.notificationsService.notifyError(this.intl.t('settings.people-data.cannot_save_error'));
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::Companies::Attributes': typeof AttributesComponent;
  }
}
