/* import __COLOCATED_TEMPLATE__ from './edit-email-address.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import { type DomainEmailAddress } from 'embercom/services/domain-service';

interface Args {
  emailAddress: DomainEmailAddress | undefined;
  changeStep: (step: string) => void;
}

interface Signature {
  Args: Args;
}

export default class EditEmailAddressComponent extends Component<Signature> {
  @service declare intercomEventService: any;
  @service declare mailForwardingValidationService: any;

  @tracked forwardingComplete = false;

  get emailForwardingEnabled() {
    return this.args.emailAddress?.forwardingEnabled || false;
  }

  get verificationTask() {
    return taskFor(this.mailForwardingValidationService.verifyEmailForwarding);
  }

  @action
  async verifyEmailForwarding() {
    if (!this.args.emailAddress) {
      return;
    }

    await this.mailForwardingValidationService.verifyEmailForwarding.perform(
      this.args.emailAddress.email,
      'connect-email',
    );
  }

  @action
  requestForwardingVerification() {
    this.verifyEmailForwarding();
    this.trackClickEvent('test_email_forwarding');
  }

  @action
  trackClickEvent(event: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: event,
      place: 'edit-email-address',
      context: 'email-setup-sidebar',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::ConnectEmail::EditEmailAddress': typeof EditEmailAddressComponent;
  }
}
