/* import __COLOCATED_TEMPLATE__ from './social-channels.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { type TabItem } from '../common/standard-base';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Args {
  model: any;
  tab: string;
  changeTab: (tab: string) => void;
}

export default class SocialChannels extends Component<Args> {
  @tracked selectedTab = 'instagram';

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (this.args.tab) {
      this.selectedTab = this.args.tab;
    }
  }

  get tabs(): Array<TabItem & { canAccess: boolean }> {
    return [
      {
        label: 'new-settings.channels.social-channels.tabs.instagram',
        value: 'instagram',
        canAccess: true,
      },
      {
        label: 'new-settings.channels.social-channels.tabs.facebook',
        value: 'facebook',
        canAccess: true,
      },
    ];
  }

  @action
  changeTab(tab: string) {
    this.selectedTab = tab;
    this.args.changeTab(tab);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::SocialChannels': typeof SocialChannels;
  }
}
