/* import __COLOCATED_TEMPLATE__ from './setup-email-forwarding.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import { taskFor } from 'ember-concurrency-ts';
import type DomainService from 'embercom/services/domain-service';
import { type DomainEmailAddress } from 'embercom/services/domain-service';

interface Signature {
  Args: {
    emailAddress: DomainEmailAddress | undefined;
    changeStep: (step: string) => void;
  };
}

export default class SetupEmailForwarding extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare intercomEventService: any;
  @service declare mailForwardingValidationService: any;
  @service declare domainService: DomainService;

  @tracked forwardingComplete = false;

  get intercomEmailAddress() {
    return this.appService.app.inbox_email;
  }

  get verificationTask() {
    return taskFor(this.mailForwardingValidationService.verifyEmailForwarding);
  }

  get verification() {
    return this.mailForwardingValidationService.verification;
  }

  @action
  trackCopyButtonClick() {
    this.trackClickEvent('copy_intercom_email_address');
  }

  @action
  async verifyEmailForwarding() {
    if (!this.args.emailAddress) {
      return;
    }

    await this.mailForwardingValidationService.verifyEmailForwarding.perform(
      this.args.emailAddress.email,
      'connect-email',
    );
    if (this.verification) {
      await this.domainService.load();
      this.args.changeStep('overview');
    }
  }

  @action
  requestForwardingVerification() {
    this.verifyEmailForwarding();
    this.trackClickEvent('test_email_forwarding');
  }

  @action
  retryForwardingVerification() {
    // Keeping this seperated as we might want to add some logic here in the future
    this.verifyEmailForwarding();
    this.trackClickEvent('resend_test_email');
  }

  @action
  trackClickEvent(event: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: event,
      place: 'setup-email-forwarding',
      context: 'email-setup-sidebar',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::ConnectEmail::SetupEmailForwarding': typeof SetupEmailForwarding;
    'new-settings/channels/email/connect-email/setup-email-forwarding': typeof SetupEmailForwarding;
  }
}
