/* import __COLOCATED_TEMPLATE__ from './setup-domain.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { type EntriConnectConfig } from 'embercom/services/entri-connect-service';
import type EntriConnectService from 'embercom/services/entri-connect-service';
import { type Domain } from 'embercom/services/domain-service';

interface Signature {
  Args: {
    changeStep: (step: string) => void;
    selectedDomain: Domain | null;
  };
}

export default class SetupDomain extends Component<Signature> {
  @service declare intercomEventService: any;
  @service declare entriConnectService: EntriConnectService;

  @action onConnectEntriClick(): void {
    let config = this.config;
    window.entri?.showEntri(config);
  }

  @action onConnectManuallyClick() {
    this.args.changeStep('setup-domain-manually');
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'connect_manually',
      place: 'setup-domain-step',
      context: 'email-setup-sidebar',
    });
  }

  get config(): EntriConnectConfig {
    return {
      applicationId: 'intercom', // this is always the same
      token: this.entriConnectService.token,
      prefilledDomain: this.args.selectedDomain?.name ?? '',
      whiteLabel: this.entriConnectService.whitelabelOptions,
      dnsRecords: this.entriConnectService.dnsRecords,
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::ConnectEmail::SetupDomain': typeof SetupDomain;
    'new-settings/channels/email/connect-email/setup-domain': typeof SetupDomain;
  }
}
