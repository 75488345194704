/* import __COLOCATED_TEMPLATE__ from './discovery-banner.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import templateOnly from '@ember/component/template-only';

const WhatsappDiscoveryBanner = templateOnly();

export default WhatsappDiscoveryBanner;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Whatsapp::DiscoveryBanner': typeof WhatsappDiscoveryBanner;
  }
}
