/* import __COLOCATED_TEMPLATE__ from './template-preview.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { CUSTOM_BOT_CONFIG } from 'embercom/objects/operator/configuration/configuration';
import type { BotConfigTarget as Target } from 'embercom/objects/operator/configuration/configuration';
import { TEMPLATE_GROUPS } from 'embercom/lib/operator/custom-bots/constants';
import type Template from 'embercom/models/template';
import type OperatorCustomBotsOverviewComponent from 'embercom/components/operator/custom-bots/overview';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { WORKFLOWS_SETUP_POPULAR_TEMPLATE_IDS } from 'embercom/lib/operator/custom-bots/constants-2';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

interface Args {
  selectedTemplateContentObject: { [key: string]: any; target: Target };
  selectedTemplate: Template;
  templates: Template[];
  onClose: () => void;
  transitionBack: () => void;
  recordAnalyticsEvent: (eventName: string) => void;
  createWorkflow: OperatorCustomBotsOverviewComponent['createCustomBot'];
  selectTemplate: (template: Template) => void;
  shouldShowPaywallBadges: boolean;
  disableTransitionBack?: boolean;
  showSimilarTemplates?: boolean;
}

enum TabbedPreview {
  WorkflowsBuilder = 'workflows-builder',
  EndUserPreview = 'end-user-preview',
}

export default class TemplatePreview extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked selectedTabbedPreview: TabbedPreview = TabbedPreview.WorkflowsBuilder;

  get tabbedPreviewOptions() {
    return [
      {
        label:
          'outbound.new-content-modal.workflows.templates-preview.tabbed-preview.workflows-builder',
        value: TabbedPreview.WorkflowsBuilder,
      },
      {
        label:
          'outbound.new-content-modal.workflows.templates-preview.tabbed-preview.end-user-preview',
        value: TabbedPreview.EndUserPreview,
      },
    ];
  }

  get editorConfig() {
    return CUSTOM_BOT_CONFIG[this.args.selectedTemplateContentObject.target];
  }

  get canUseWorkflowsSetup() {
    return this.appService.app.canUseWorkflowsSetup;
  }

  get modalTitle() {
    if (this.canUseWorkflowsSetup) {
      return this.intl.t('outbound.new-content-modal.workflows.templates-preview.header-title');
    }
    return this.args.selectedTemplate.title;
  }

  get templateGroup() {
    return TEMPLATE_GROUPS.find((group) => {
      return this.args.selectedTemplate.objective === group.id;
    }) as (typeof TEMPLATE_GROUPS)[number];
  }

  get shouldShowEndUserPreviewButton() {
    return (
      this.canUseWorkflowsSetup &&
      WORKFLOWS_SETUP_POPULAR_TEMPLATE_IDS.includes(this.args.selectedTemplate.id)
    );
  }

  @action
  onTabbedPreviewSelected(value: TabbedPreview) {
    if (this.shouldShowEndUserPreviewButton) {
      this.selectedTabbedPreview = value;
    }
  }

  get endUserPreviewImageUrl() {
    return assetUrl(
      `/assets/images/workflows/workflows-setup-user-end-preview-${this.args.selectedTemplate.id}-template.gif`,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::NewContentModal::Workflows::TemplatePreview': typeof TemplatePreview;
    'outbound/new-content-modal/workflows/template-preview': typeof TemplatePreview;
  }
}
