/* import __COLOCATED_TEMPLATE__ from './empty-state.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import {
  legacyObjectTypes,
  objectNames,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import { LEARN_MORE_CONTENT_IDS } from 'embercom/lib/outbound/constants';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { objectIcons } from 'embercom/models/data/matching-system/matching-constants';
import { capitalize } from '@ember/string';
import type IntlService from 'ember-intl/services/intl';
import type Template from 'embercom/models/template';
import type SeriesTemplate from 'embercom/models/series/template';
import { type EntityType } from 'embercom/models/data/entity-types';

const ALL = 'all';
const TRY_OUT = [objectTypes.survey, objectTypes.checklist, objectTypes.tour];
const TEMPLATES = ['30', '17', '48', '38', '28', '974', '1091', '848'];
const NEW_EMPTY_STATE_TEMPLATES = ['21', '38', '28'];

type EmptyStateValues = Record<
  string,
  {
    image: string;
    header: string;
    summary: string;
  }
>;

type TemplateType = Template | SeriesTemplate;

type Signature = {
  Args: {
    templates: TemplateType[];
    selectedTemplate: TemplateType;
    totalCount: number;
    hasAppliedFilters: boolean;
    selectedObjectTypes: number[];
    disableNewSmsButton: boolean;
    createContent: (data: $TSFixMe) => void;
    data: $TSFixMe;
    selectedContentType?: EntityType;
  };
};

export default class EmptyState extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;
  @service declare outboundHomeService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;

  @tracked showContentCreationModal = false;
  @tracked selectedTemplate = this.args.selectedTemplate;

  get app() {
    return this.appService.app;
  }

  get showNoMessagesText() {
    return this.args.totalCount === 0 && !this.args.hasAppliedFilters;
  }

  get selectedObjectTypes() {
    return this.args.selectedObjectTypes?.filter(
      (objectType) =>
        Number(objectType) !== legacyObjectTypes.legacyMessageEmail &&
        Number(objectType) !== legacyObjectTypes.legacyMessagePush,
    );
  }

  get singleContentTypeIsSelected() {
    return this.selectedObjectTypes?.length === 1;
  }

  get showTryOutButton() {
    return this.singleContentTypeIsSelected && TRY_OUT.includes(this.selectedObjectTypes[0]);
  }

  get selectedObjectName() {
    return objectNames[this.selectedObjectTypes[0]];
  }

  get tryOutLabel() {
    return `${this.intl.t('outbound.empty-state.try-out')}${this.selectedObjectName}`;
  }

  get tryIcon() {
    return objectIcons[this.selectedObjectTypes[0]];
  }

  get allEmptyStateValues(): EmptyStateValues {
    let contentTypes: EmptyStateValues = {};

    this.outboundHomeService.contentToDisplay.forEach((type: EntityType) => {
      contentTypes[objectNames[type]] = {
        image: assetUrl(`/assets/images/outbound/${objectNames[type]}-empty-state.png`),
        header: `${this.intl.t(`outbound.empty-state.${objectNames[type]}.header`)}`,
        summary: `${this.intl.t(`outbound.empty-state.${objectNames[type]}.summary`)}`,
      };
    });
    return contentTypes;
  }

  get emptyStateValue() {
    return this.allEmptyStateValues[this.selectedObjectName];
  }

  get templates(): TemplateType[] {
    let templates: TemplateType[] = [];

    let templateIds = TEMPLATES;
    if (this.app.canUseNewOutboundEmptyState) {
      templateIds = NEW_EMPTY_STATE_TEMPLATES;
    }

    templateIds.forEach((templateId) => {
      let template = this.args.templates.find((template) => template.id === templateId);
      if (template) {
        templates.push(template);
      }
    });
    return templates;
  }

  _trackAnalytics(contentType: string, id: number, target: string) {
    let targetObject = `empty_state_${target}`;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: targetObject,
      place: 'outbound_all',
      content_type: contentType,
      link_to: id,
    });
  }

  @action
  startTryOut() {
    let contentId =
      LEARN_MORE_CONTENT_IDS[this.selectedObjectName as keyof typeof LEARN_MORE_CONTENT_IDS];
    window.Intercom(`start${capitalize(this.selectedObjectName)}`, contentId);
    this._trackAnalytics(this.selectedObjectName, contentId, 'try_out');
  }

  @action
  showLearnMoreArticle() {
    let contentType = this.singleContentTypeIsSelected ? this.selectedObjectName : ALL;
    let contentId = LEARN_MORE_CONTENT_IDS[contentType as keyof typeof LEARN_MORE_CONTENT_IDS];
    window.Intercom('showArticle', contentId);
    this._trackAnalytics(contentType, contentId, 'learn_more');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::EmptyState': typeof EmptyState;
    'outbound/empty-state': typeof EmptyState;
  }
}
