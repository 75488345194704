/* import __COLOCATED_TEMPLATE__ from './domain-records.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type DkimSettings from 'embercom/models/dkim-settings';
import type CustomBounceSettings from 'embercom/models/custom-bounce-settings';

interface Args {
  dkimSettings: DkimSettings;
  bounceSettings: CustomBounceSettings;
}

export default class DomainRecords extends Component<Args> {
  @service declare intl: IntlService;

  get domainSettingRecords() {
    let rows = [
      {
        name: this.args.dkimSettings?.domainForCustomerDnsRecord,
        type: this.intl.t('new-settings.channels.email.addresses.domain-sidebar.cname'),
        value: `${this.args.dkimSettings?.domainForIntercomDnsRecord}`,
        status: this.args.dkimSettings?.validRecordExists,
        id: 'dkim-cname',
      },
      {
        name: this.args.bounceSettings?.host,
        type: this.intl.t('new-settings.channels.email.addresses.domain-sidebar.cname'),
        value: `${this.args.bounceSettings?.value}`,
        status: this.args.bounceSettings?.validRecordExists,
        id: 'bounce-settings-cname',
      },
      {
        name: `_dmarc.${this.args.dkimSettings?.domain}`,
        type: this.intl.t('new-settings.channels.email.addresses.domain-sidebar.txt'),
        value: 'v=DMARC1; p=none',
        status: this.args.dkimSettings?.validDmarcExists,
        id: 'dmarc-txt',
      },
    ];
    return rows;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Addresses::DomainRecords': typeof DomainRecords;
  }
}
