/* import __COLOCATED_TEMPLATE__ from './email-setup-sidebar.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { type DomainEmailAddress, type Domain } from 'embercom/services/domain-service';
import type IntlService from 'embercom/services/intl';

interface Signature {
  Args: {
    email: string | null;
    step: string | null;
    isOpen: boolean;
    onClose: () => void;
    changeStep: (step: string) => void;
    setEmail: (email: string | null) => Promise<void>;
    selectedDomain: Domain | null;
  };
}

export default class EmailSetupSidebar extends Component<Signature> {
  @service declare intercomEventService: any;
  @service declare intl: IntlService;

  get headerText() {
    if (this.args.step === 'add-address') {
      return this.intl.t(
        'new-settings.channels.email.connect-email.email-setup.add-address-step.header',
      );
    } else if (this.args.step === 'overview') {
      return this.intl.t(
        'new-settings.channels.email.connect-email.email-setup.overview-step.header',
      );
    } else if (this.args.step === 'setup-email-forwarding') {
      return this.intl.t(
        'new-settings.channels.email.connect-email.email-setup.setup-email-forwarding-step.header',
      );
    } else if (this.args.step === 'setup-domain-manually' || this.args.step === 'setup-domain') {
      return this.intl.t(
        'new-settings.channels.email.connect-email.email-setup.setup-domain-step.header',
      );
    } else if (this.args.step === 'edit-address') {
      return this.intl.t(
        'new-settings.channels.email.connect-email.email-setup.edit-address.header',
      );
    } else {
      return this.intl.t(
        'new-settings.channels.email.connect-email.email-setup.edit-address.header',
      );
    }
  }

  get subHeaderText() {
    if (this.args.step === 'overview' || this.args.step === 'setup-email-forwarding') {
      return this.intl.t('new-settings.channels.email.connect-email.email-setup.email-setup-for', {
        email: this.args.email,
        htmlSafe: true,
      });
    } else if (this.args.step === 'setup-domain-manually') {
      return this.intl.t('new-settings.channels.email.connect-email.email-setup.domain-setup-for', {
        domainName: this.args.selectedDomain?.name || 'company.com',
        htmlSafe: true,
      });
    } else if (this.args.step === 'edit-address') {
      return this.intl.t('new-settings.channels.email.connect-email.email-setup.editing-address', {
        email: this.args.email,
        htmlSafe: true,
      });
    } else {
      return '';
    }
  }

  get emailAddress() {
    return this.args.selectedDomain?.addresses.find(
      (address: DomainEmailAddress) => address.email === this.args.email,
    );
  }

  @action
  handleBackButtonClick() {
    window.history.back();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'back_button',
      place: `${this.args.step}-step`,
      context: 'email-setup-sidebar-header',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::ConnectEmail::EmailSetupSidebar': typeof EmailSetupSidebar;
    'new-settings/channels/email/connect-email/email-setup-sidebar': typeof EmailSetupSidebar;
  }
}
