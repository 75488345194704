/* import __COLOCATED_TEMPLATE__ from './image-card.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import templateOnlyComponent from '@ember/component/template-only';

interface ImageCardArgs {
  isInteractive: boolean;
  url?: string;
  imageAlt?: string;
}

interface Signature {
  Args: ImageCardArgs;
  Element: HTMLElement;
  Blocks: {
    default: [];
  };
}

const ImageCard = templateOnlyComponent<Signature>();
export default ImageCard;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Whatsapp::Sidebar::ImageCard': typeof ImageCard;
  }
}
