/* import __COLOCATED_TEMPLATE__ from './empty-state-template-picker.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import type Template from 'embercom/models/template';
import type SeriesTemplate from 'embercom/models/series/template';
import { type EntityType } from 'embercom/models/data/entity-types';
import templateOnlyComponent from '@ember/component/template-only';

type TemplateTypes = Template | SeriesTemplate;

interface Signature {
  Args: {
    templates: TemplateTypes[];
    selectedContentType?: EntityType;
    onTemplateSelect: (template: TemplateTypes) => void;
    onSeeAllButtonClick: () => void;
  };
}

const EmptyStateTemplatePicker = templateOnlyComponent<Signature>();
export default EmptyStateTemplatePicker;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::EmptyStateTemplatePicker': typeof EmptyStateTemplatePicker;
    'outbound/empty-state-template-picker': typeof EmptyStateTemplatePicker;
  }
}
