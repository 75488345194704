/* import __COLOCATED_TEMPLATE__ from './dependency-warning-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';
import { action } from '@ember/object';
import type { Brand } from './types/brand';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type { DependencyData } from './sidebar';

interface Args {
  brand: Brand;
  dependencyData: DependencyData;
  isFetchingDependentObjects: boolean;
  isDeleting: boolean;
  onDelete: () => void;
  onClose: () => void;
}

interface Signature {
  Args: Args;
}

const MAX_DISPLAYED = 5;

export default class DependencyWarningModal extends Component<Signature> {
  @service declare router: Router;

  get emailAddresses() {
    return this.args.dependencyData.dependent_email_addresses || [];
  }

  get messengerStyles() {
    return this.args.dependencyData.dependent_messenger_styles || [];
  }

  get isSafeToDelete() {
    return this.emailAddresses.length === 0 && this.messengerStyles.length === 0;
  }

  get displayedEmailAddresses() {
    if (this.emailAddresses.length <= MAX_DISPLAYED) {
      return this.emailAddresses;
    }

    return this.emailAddresses.slice(0, MAX_DISPLAYED);
  }

  get remainingEmailAddressCount() {
    return Math.max(0, this.emailAddresses.length - MAX_DISPLAYED);
  }

  get displayedMessengerStyles() {
    if (this.messengerStyles.length <= MAX_DISPLAYED) {
      return this.messengerStyles;
    }

    return this.messengerStyles.slice(0, MAX_DISPLAYED);
  }

  get remainingMessengerStylesCount() {
    return Math.max(0, this.messengerStyles.length - MAX_DISPLAYED);
  }

  @action openEmailAddressesInNewWindow() {
    let actionsRoute = 'apps.app.settings.channels.email';
    let url: string = this.router.urlFor(actionsRoute, {
      queryParams: {
        tab: 'addresses',
      },
    });
    safeWindowOpen(url);
  }

  @action openMessengerStylesInNewWindow() {
    let actionsRoute = 'apps.app.settings.channels.messenger.web';
    let url: string = this.router.urlFor(actionsRoute, {
      queryParams: {
        section: 'general',
        tab: 'styling',
      },
    });
    safeWindowOpen(url);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Brands::DependencyWarningModal': typeof DependencyWarningModal;
    'new-settings/workspace/brands/dependency-warning-modal': typeof DependencyWarningModal;
  }
}
