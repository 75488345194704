/* import __COLOCATED_TEMPLATE__ from './match-objects-table.hbs'; */
/* RESPONSIBLE TEAM: team-actions */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type ImportConfigurationService from 'embercom/services/data/import-configuration-service';
import { taskFor } from 'ember-concurrency-ts';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator, type TaskInstance } from 'ember-concurrency';
import type IdentityMapping from 'embercom/models/crm/identity-mapping';

export interface Args {
  integrationCode: string;
  objectType: string;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class MatchObjectsTable extends Component<Signature> {
  @service declare intl: IntlService;
  @service('data/import-configuration-service')
  declare importConfigurationService: ImportConfigurationService;

  @tracked task!: TaskInstance<any>;

  loadTableData = (objectType: string) => {
    // to force auto-tracking of the returned TaskInstance, to allow for loading state
    this.task = taskFor(this.loadCrmObjectTask).perform(objectType);
    return this.task;
  };

  get tableData() {
    return this.importConfigurationService.listIdentityMappings(this.args.objectType);
  }

  @dropTask *loadCrmObjectTask(objectType: string): TaskGenerator<any> {
    yield this.importConfigurationService.loadCrmObjects(this.args.integrationCode, objectType);
  }

  crmObjectLabel = (mapping: IdentityMapping) => {
    let object = this.importConfigurationService.matchingCrmObject(mapping);
    if (object !== undefined) {
      return object.name;
    }
    return '';
  };

  translation = (path: string) => this.intl.t(this.translationKey(path));
  private translationKey = (path: string) =>
    `new-settings.data.imports-exports.import-zendesk.config-import-page.match-objects-table.${this.args.objectType}.${path}`;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk::ConfigImport::Sections::Lib::MatchObjectsTable': typeof MatchObjectsTable;
    'new-settings/data/imports-exports/import-zendesk/config-import/sections/lib/match-objects-table': typeof MatchObjectsTable;
  }
}
