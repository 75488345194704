/* import __COLOCATED_TEMPLATE__ from './overview-step.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import { type Domain, type DomainEmailAddress } from 'embercom/services/domain-service';
import type SenderEmailVerificationService from 'embercom/services/sender-email-verification-service';
import { type SafeString } from 'handlebars';

interface Signature {
  Args: {
    emailAddress: DomainEmailAddress | undefined;
    changeStep: (step: string) => void;
    selectedDomain: Domain | null;
  };
}

export default class OverviewStep extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare senderEmailVerificationService: SenderEmailVerificationService;
  @service declare intercomEventService: $TSFixMe;

  get app() {
    return this.appService.app;
  }

  get isEmailVerified() {
    return this.args.emailAddress?.verified;
  }

  get emailForwardingEnabled() {
    return this.args.emailAddress?.forwardingEnabled || false;
  }

  get domainAuthenticated() {
    return this.args.selectedDomain?.isAuthenticated;
  }

  get emailVerificationStepData(): {
    title: string | SafeString;
    description: string | SafeString;
    ctaLabel?: string;
    ctaType?: string;
    stepCompleted?: boolean;
    ctaLink: string;
    ctaOnClick: () => Promise<void>;
  } {
    let isVerified = this.isEmailVerified;
    let email = this.args.emailAddress?.email;

    return {
      title: isVerified
        ? this.intl.t(
            'new-settings.channels.email.connect-email.email-setup.overview-step.verify-email.title-verified',
            { email, htmlSafe: true },
          )
        : this.intl.t(
            'new-settings.channels.email.connect-email.email-setup.overview-step.verify-email.title-verifying',
          ),
      description: isVerified
        ? this.intl.t(
            'new-settings.channels.email.connect-email.email-setup.overview-step.verify-email.description-verified',
            { email, htmlSafe: true },
          )
        : this.intl.t(
            'new-settings.channels.email.connect-email.email-setup.overview-step.verify-email.description-verifying',
            { email, htmlSafe: true },
          ),
      ctaLabel: isVerified
        ? undefined
        : this.intl.t(
            'new-settings.channels.email.connect-email.email-setup.overview-step.verify-email.cta-resend-verification-email',
          ),
      ctaType: isVerified ? undefined : 'primary',
      stepCompleted: isVerified,
      ctaOnClick: this.resendVerificationEmail.bind(this),
      ctaLink: '',
    };
  }

  async resendVerificationEmail() {
    await this.senderEmailVerificationService.resendVerificationEmail({
      senderEmailAddressSettingsId: this.args.emailAddress?.id?.toString() ?? '',
      appId: this.appService.app.id,
      adminId: this.appService.app.currentAdmin.id,
    });
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'resend_verification_email',
      place: 'overview',
      context: 'email-setup-sidebar',
    });
  }

  @action onForwardEmailsClick() {
    this.args.changeStep('setup-email-forwarding');
  }

  @action onSetupDomainClick() {
    if (this.app.canUseEntriConnect) {
      this.args.changeStep('setup-domain');
    } else {
      this.args.changeStep('setup-domain-manually');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::ConnectEmail::OverviewStep': typeof OverviewStep;
    'new-settings/channels/email/connect-email/overview-step': typeof OverviewStep;
  }
}
