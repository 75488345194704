/* import __COLOCATED_TEMPLATE__ from './general-links-accordion-old.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';
import { extractInvalidDomainPatterns } from 'embercom/lib/domain-patterns';
import { putRequest, request } from 'embercom/lib/inbox/requests';

interface Args {
  attachmentSettings: any;
  accordion: any;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

interface Payload {
  app_id: string;
  enabled: boolean;
  trusted_domains?: string;
}

interface LinksResponse {
  enabled: boolean;
  trusted_domains: string;
  updated_at: string;
  updated_by_admin_id: number;
}

export default class GeneralLinksAccordionOld extends Component<Signature> {
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;

  @tracked useLinkWarnings = true;
  @tracked showLinkModal = false;
  @tracked hasLinkSectionBeenUpdated = false;
  @tracked hasUserCancelledLinkToggle = false;
  @tracked localTrustedDomainsList = '';
  @tracked savedTrustedDomainsList = '';
  @tracked trustedDomainSettings = {
    enabled: true,
    updatedAt: '',
    adminName: '',
  };
  @tracked invalidTrustedDomainsList: string[] = [];
  @tracked isVerificationSettingsLoading = true;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.loadURLVerificationSettings();
  }

  get isSaveLinkTaskRunning() {
    return taskFor(this.saveLinkTask).isRunning;
  }

  get canSaveTrustedDomainsSettings() {
    let hasTrustedDomainListBeenUpdated = this.hasTrustedDomainListBeenUpdated();
    return (
      (this.hasLinkSectionBeenUpdated || hasTrustedDomainListBeenUpdated) &&
      !this.showLinkModal &&
      !this.hasUserCancelledLinkToggle &&
      !taskFor(this.saveLinkTask).isRunning &&
      this.invalidTrustedDomainsList.length === 0
    );
  }

  @action
  toggleUseLinkWarnings() {
    if (this.useLinkWarnings) {
      this.openLinkModal();
    } else {
      this.enableUseTrustedDomainsForUrlVerification();
    }
  }

  @action
  openLinkModal() {
    this.showLinkModal = true;
  }

  @action
  enableUseTrustedDomainsForUrlVerification() {
    this.useLinkWarnings = true;
    this.hasLinkSectionBeenUpdated = true;
  }

  @action
  closeLinkModalAndDisableSave() {
    this.hasUserCancelledLinkToggle = true;
    this.showLinkModal = false;
    this.hasLinkSectionBeenUpdated = false;
  }

  @action
  disableUseTrustedDomainsForUrlVerification() {
    this.args.attachmentSettings.set('useTrustedDomainsForUrlVerification', false);
    this.useLinkWarnings = false;
    this.closeLinkModalAndEnableSave();
  }

  @action
  closeLinkModalAndEnableSave() {
    this.useLinkWarnings = false;
    this.hasUserCancelledLinkToggle = false;
    this.hasLinkSectionBeenUpdated = true;
    this.showLinkModal = false;
  }

  @action
  updateTrustedDomains(value: string) {
    this.localTrustedDomainsList = value;
    this.invalidTrustedDomainsList = extractInvalidDomainPatterns(this.localTrustedDomainsList);
  }

  private hasTrustedDomainListBeenUpdated() {
    let hasUpdated = this.localTrustedDomainsList !== this.savedTrustedDomainsList;
    if (hasUpdated) {
      this.hasUserCancelledLinkToggle = false;
    }
    return hasUpdated;
  }

  async loadURLVerificationSettings() {
    try {
      let response = await request(
        `/ember/trusted_domains_settings/show?app_id=${this.appService.app.id}`,
      );
      this.isVerificationSettingsLoading = false;
      let linkResponse: LinksResponse = await response.json();

      this.useLinkWarnings = linkResponse.enabled;
      this.localTrustedDomainsList = linkResponse.trusted_domains;
      this.savedTrustedDomainsList = linkResponse.trusted_domains;

      this.updateTrustedDomainSettings(linkResponse);
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('apps.app.settings.attachment-settings.load-failed'),
      });
    }
  }

  @dropTask
  *saveLinkTask() {
    try {
      let payload: Payload = {
        app_id: this.appService.app.id,
        enabled: this.useLinkWarnings,
      };

      if (this.useLinkWarnings) {
        payload.trusted_domains = this.localTrustedDomainsList;
      }

      let response: Response = yield putRequest(`/ember/trusted_domains_settings/update`, payload);

      let linkResponse: LinksResponse = yield response.json();

      this.notificationsService.notifyConfirmation(
        this.intl.t('apps.app.settings.attachment-settings.save-successful'),
      );
      this.hasLinkSectionBeenUpdated = false;
      this.localTrustedDomainsList = linkResponse.trusted_domains;
      this.savedTrustedDomainsList = linkResponse.trusted_domains;
      this.updateTrustedDomainSettings(linkResponse);
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('apps.app.settings.attachment-settings.save-failed'),
      });
    }
  }

  private updateTrustedDomainSettings(response: any) {
    if (!response.updated_by_admin_id) {
      this.trustedDomainSettings = {
        enabled: response.enabled,
        updatedAt: response?.updated_at ? response.updated_at : '',
        adminName: '',
      };
      return;
    }
    let id = `${response.updated_by_admin_id}`;
    let admin = this.appService.app.admins.findBy('id', id);
    this.trustedDomainSettings = {
      enabled: response.enabled,
      updatedAt: response?.updated_at ? response.updated_at : '',
      adminName: admin?.name ? admin.name : '',
    };
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Security::GeneralLinksAccordionOld': typeof GeneralLinksAccordionOld;
    'new-settings/workspace/security/general-links-accordion-old': typeof GeneralLinksAccordionOld;
  }
}
