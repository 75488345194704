/* import __COLOCATED_TEMPLATE__ from './required-attribute-mapping-table.hbs'; */
/* RESPONSIBLE TEAM: team-actions */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type ImportConfigurationService from 'embercom/services/data/import-configuration-service';
import type AttributeMapping from 'embercom/models/crm/attribute-mapping';
import type { MappingVisibility } from 'embercom/models/crm/attribute-mapping';
import type Attribute from 'embercom/models/attribute';
import { ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP } from 'embercom/models/custom-objects/constants/attribute-descriptor-types';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export interface Args {
  integrationCode: string;
  sectionName: string;
  mappingVisibility: MappingVisibility;
  ticketMappings: AttributeMapping[];
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class RequiredAttributeMappingTable extends Component<Signature> {
  @service declare intl: IntlService;
  @service('data/import-configuration-service')
  declare importConfigurationService: ImportConfigurationService;

  destinationAttribute = (mapping: AttributeMapping): Attribute | undefined => {
    return this.importConfigurationService.matchingDestinationAttribute(mapping);
  };

  destinationAttributeIcon(attribute: Attribute | undefined): InterfaceIconName {
    if (attribute === undefined) {
      return 'alert';
    }
    let key = attribute.type as keyof typeof ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP;
    return ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP[key] as InterfaceIconName;
  }

  destinationAttributeLabel = (attribute: Attribute | undefined, mapping: AttributeMapping) => {
    if (attribute === undefined) {
      return mapping.destinationAttributePath;
    }
    return attribute.name || attribute.identifier;
  };

  translation = (path: string) => this.intl.t(this.translationKey(path));
  private translationKey = (path: string) =>
    `new-settings.data.imports-exports.import-zendesk.config-import-page.required-attribute-mapping-table.${path}`;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk::ConfigImport::Sections::Lib::RequiredAttributeMappingTable': typeof RequiredAttributeMappingTable;
    'new-settings/data/imports-exports/import-zendesk/config-import/sections/lib/required-attribute-mapping-table': typeof RequiredAttributeMappingTable;
  }
}
