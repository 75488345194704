/* import __COLOCATED_TEMPLATE__ from './select-admin-dropdown-option.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import templateOnlyComponent from '@ember/component/template-only';
import type Team from 'embercom/models/team';
import type Admin from 'embercom/models/admin';

export interface Args {
  item: { text: string; value: string };
  componentAttrs: { model: Team | Admin };
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const SelectAdminDropdownOption = templateOnlyComponent<Signature>();
export default SelectAdminDropdownOption;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk::ConfigImport::Sections::Lib::SelectAdminDropdownOption': typeof SelectAdminDropdownOption;
    'new-settings/data/imports-exports/import-zendesk/config-import/sections/lib/select-admin-dropdown-option': typeof SelectAdminDropdownOption;
  }
}
