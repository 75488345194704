/* import __COLOCATED_TEMPLATE__ from './import-zendesk.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';
import { type ImportZendeskContext } from 'embercom/lib/apps/app/new-settings/data/imports-exports/import-zendesk/zendesk-context-loader';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export interface Args {
  context?: ImportZendeskContext;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export type ZendeskConnectionData = {
  isConnected: boolean;
  subdomain: string;
  hasStartedMigration: boolean;
};

export enum IntercomObjectTypes {
  Company = 'company',
  Contact = 'contact',
  Ticket = 'ticket',
}

export default class ImportZendesk extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare session: Session;

  @tracked connectionData: ZendeskConnectionData = {
    isConnected: false,
    subdomain: '',
    hasStartedMigration: false,
  };

  integrationCode = 'migrate-from-zendesk';

  constructor(owner: unknown, args: any) {
    super(owner, args);

    if (this.args.context !== undefined) {
      this.updateConnectionData(
        this.args.context.isConnected,
        this.args.context.subdomain,
        this.args.context.hasStartedMigration,
      );
    }
  }

  @action updateConnectionData(
    isConnected: boolean,
    subdomain: string,
    hasStartedMigration: boolean,
  ) {
    this.connectionData = { isConnected, subdomain, hasStartedMigration };
  }

  translation = (path: string) => this.intl.t(this.translationKey(path));
  private translationKey = (path: string) =>
    `new-settings.data.imports-exports.import-zendesk.${path}`;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk': typeof ImportZendesk;
    'new-settings/data/imports-exports/import-zendesk': typeof ImportZendesk;
  }
}
