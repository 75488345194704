/* import __COLOCATED_TEMPLATE__ from './segments.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

export default class SegmentsComponent extends Component {
  @service declare appService: any;
  @service declare intl: IntlService;

  get app() {
    return this.appService.app;
  }

  get columns() {
    return [
      {
        valuePath: 'name',
        label: this.intl.t('settings.people-segments.segment-name'),
        isSortable: true,
      },
      {
        valuePath: 'users',
        label: this.intl.t('settings.people-segments.users'),
        isSortable: true,
      },
      {
        valuePath: 'delete',
      },
    ];
  }

  get nonDeletedSegments() {
    return this.app.userSegments.filter((segment: any) => !segment.isDeleted);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::People::Segments': typeof SegmentsComponent;
  }
}
