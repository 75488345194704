/* import __COLOCATED_TEMPLATE__ from './add-email-address.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
/* eslint-disable @intercom/intercom/no-bare-strings */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import type { TaskGenerator } from 'ember-concurrency';
import type SenderEmailAddressSettings from 'embercom/models/sender-email-address-settings';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import { taskFor } from 'ember-concurrency-ts';
import type CustomEmailAddress from 'embercom/models/custom-email-address';
import { isValidEmail as isValidEmailAddress } from 'embercom/lib/email';

interface Signature {
  changeStep: (step: string) => void;
  setEmail: (email: string | null) => Promise<void>;
}

export default class AddEmailAddressComponent extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked newEmail = '';

  get canSaveEmail() {
    return this.isValidEmail && !this.isPersonalEmail;
  }

  get isValidEmail() {
    return isValidEmailAddress(this.newEmail);
  }

  get isPersonalEmail() {
    let personalDomains = [
      'gmail.com',
      'yahoo.com',
      'outlook.com',
      'hotmail.com',
      'aol.com',
      'icloud.com',
      'protonmail.com',
      'mail.com',
      'zoho.com',
      'yandex.com',
    ];

    let emailDomain = this.newEmail.split('@')[1]?.toLowerCase();
    return emailDomain && personalDomains.includes(emailDomain);
  }

  private buildSenderEmailAddresses(): CustomEmailAddress[] {
    let senderEmailAddresses: CustomEmailAddress[] = [];

    senderEmailAddresses.push(
      this.store.createRecord('custom-email-address', {
        email: this.newEmail,
        name: this.appService.app.name,
        status: 'Unverified email',
      }),
    );
    return senderEmailAddresses;
  }

  private pruneAddresses(settings: SenderEmailAddressSettings) {
    // seems to be a bug in our version of ember-data where we end up with both the
    // saved & unsaved records here, need to look at whether we can fix this with a local ID?
    // https://github.com/emberjs/data/issues/7354
    let unsaved = settings.senderEmailAddresses.filter((x) => x.isNew);
    settings.senderEmailAddresses.removeObjects(unsaved);
  }

  @action
  saveAddress() {
    taskFor(this.createTask).perform();
  }

  @dropTask
  *createTask(): TaskGenerator<void> {
    try {
      let newSettings = this.store.createRecord('sender-email-address-settings', {
        email: this.newEmail,
        senderEmailAddresses: this.buildSenderEmailAddresses(),
      });

      yield newSettings.save();
      this.pruneAddresses(newSettings);

      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'new-settings.channels.email.connect-email.email-setup.add-address-step.success-message',
        ),
      );
      yield this.args.setEmail(this.newEmail);
      this.args.changeStep('overview');
    } catch (error) {
      console.error('createTask() error', error);
      let errorStatus = error.jqXHR?.status;
      let notificationMessage;

      if (errorStatus === 422) {
        notificationMessage = error.jqXHR?.responseJSON?.errors[0]?.message;
      } else {
        notificationMessage = this.intl.t(
          'new-settings.channels.email.connect-email.email-setup.add-address-step.error-message',
        );
      }
      this.notificationsService.notifyResponseError(error, {
        default: notificationMessage,
      });
      return;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::ConnectEmail::AddEmailAddress': typeof AddEmailAddressComponent;
  }
}
