/* import __COLOCATED_TEMPLATE__ from './instagram.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    model: {
      appPackage: any;
      integrations: any[];
      settings: any;
    };
  };
}

export default class Instagram extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;

  @action
  showRemoveModal(_data: any) {
    // TODO: Implement remove modal logic
  }

  @action
  addInstagramAccount() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'add_instagram_account_button',
      place: 'settings_channels_instagram',
      section: 'add_instagram_account',
    });
    // TODO: Implement add Instagram account logic
  }

  @action
  confirmInstagramAccount(_pageId: string) {
    // TODO: Implement confirm Instagram account logic
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Instagram': typeof Instagram;
  }
}
