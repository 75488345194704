/* import __COLOCATED_TEMPLATE__ from './free-trial-button.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { SideSheetKeys } from 'embercom/objects/workflows/graph-editor/editor-state';

import type FinTrialService from 'embercom/services/fin-trial-service';
import type IntlService from 'embercom/services/intl';
import type Router from '@ember/routing/router-service';

export interface Args {
  context?: SideSheetKeys;
}

export enum AIAutomationTabs {
  setLiveForChat = 'set-live-for-chat',
  setLiveForEmail = 'set-live-for-email',
}
interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

export default class FreeTrialButton extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare finTrialService: FinTrialService;
  @service declare purchaseAnalyticsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare router: Router;

  @tracked showModal = false;

  get isWorkflowsContext() {
    return this.args.context === SideSheetKeys.AIAgentSettings;
  }

  get inFinSetUpArea() {
    return this.router.currentRouteName.includes('automation.fin-ai-agent.setup');
  }

  get setLiveForChatTab() {
    return AIAutomationTabs.setLiveForChat;
  }

  get setLiveForEmailTab() {
    return AIAutomationTabs.setLiveForEmail;
  }

  get showFinFromChatOrEmailTab() {
    if (this.isWorkflowsContext) {
      return false;
    }
    let queryParams = this.router.currentRoute?.queryParams;
    if (
      (queryParams && queryParams.activeTab === AIAutomationTabs.setLiveForChat) ||
      queryParams.activeTab === AIAutomationTabs.setLiveForEmail
    ) {
      return false;
    }
    if (this.inFinSetUpArea) {
      return true;
    }
    return false;
  }

  get showButton() {
    return this.finTrialService.showCTAs;
  }

  get buttonLabel() {
    if (this.finTrialService.freeTrialNotStarted) {
      return this.intl.t('operator.fin.free-trial-button.not-started');
    } else if (this.finTrialService.freeTrialStarted) {
      return this.intl.t('operator.fin.free-trial-button.started', {
        remainingDays: this.finTrialService.trialDaysRemaining,
      });
    } else {
      return this.intl.t('operator.fin.free-trial-button.expired');
    }
  }

  get modalHeader() {
    if (this.finTrialService.freeTrialNotStarted) {
      return this.intl.t('operator.fin.free-trial-button.modal.heading.not-started');
    } else if (this.finTrialService.freeTrialStarted) {
      return this.intl.t('operator.fin.free-trial-button.modal.heading.started', {
        remainingDays: this.finTrialService.trialDaysRemaining,
      });
    } else {
      return this.intl.t('operator.fin.free-trial-button.modal.heading.expired');
    }
  }

  @action
  openModal() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'opened_modal',
      place: 'fin_set_up',
      context: this.appService.app.aiAnswersState,
      object: 'fin_free_trial_button',
    });
    this.showModal = true;
  }

  @action
  closeModal() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'closed_modal',
      place: 'fin_set_up',
      context: this.appService.app.aiAnswersState,
      object: 'fin_free_trial_button',
    });
    this.showModal = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::FreeTrialButton': typeof FreeTrialButton;
  }
}
