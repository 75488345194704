/* import __COLOCATED_TEMPLATE__ from './whatsapp.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import { taskFor } from 'ember-concurrency-ts';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { dropTask, restartableTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import whatsappEmbeddedSignup from 'embercom/lib/channels/whatsapp/helpers';
import { post } from 'embercom/lib/ajax';
import ENV from 'embercom/config/environment';
import { type TaskGenerator } from 'ember-concurrency';
import type WhatsappIntegration from 'embercom/models/whatsapp/integration';
import safeWindowOpen from 'embercom/lib/safe-window-open';

interface Args {
  model: {
    integrations: WhatsappIntegration[];
    settings: any;
    appPackage: any;
  };
}

export default class Whatsapp extends Component<Args> {
  @service declare intercomEventService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare fb: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare store: $TSFixMe;
  @service declare intl: $TSFixMe;

  @tracked isSidebarOpen = false;
  @tracked selectedSidebarContent = 'connected-accounts';
  @tracked accountPhoneNumbers: any;
  @tracked showMultiplePhoneNumbers = false;
  @tracked selectedNumber: any;
  @tracked isWhatsappInstalled = false;
  @tracked removingIntegration: WhatsappIntegration | null = null;
  @tracked showModal = false;
  @tracked selectedIntegration?: WhatsappIntegration;
  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.initFacebook(this.fb);
    this.selectedIntegration = this.firstConnectedIntegration;
  }

  get app() {
    return this.appService.app;
  }

  get whatsappIntegrations() {
    return this.args.model.integrations;
  }

  get connectedWhatsappIntegrations() {
    return this.whatsappIntegrations.filter((integration) => integration.isPhoneNumberConnected);
  }

  get firstConnectedIntegration() {
    return this.connectedWhatsappIntegrations.firstObject;
  }

  get lastWhatsappIntegration() {
    return this.whatsappIntegrations.lastObject;
  }

  get isLoading() {
    return (
      taskFor(this.setupWhatsapp).isRunning ||
      taskFor(this.fetchAccountPhoneNumbers).isRunning ||
      taskFor(this.submitNumber).isRunning
    );
  }

  get integrationsDropdownData() {
    let dropdownItems = this.connectedWhatsappIntegrations.map((integration) => {
      return {
        text: integration.displayString,
        value: integration,
        isSelected: this.selectedIntegration?.id === integration.id,
      };
    });

    return [
      {
        hasItemsMarkableAsSelected: true,
        items: dropdownItems,
      },
    ];
  }

  @action
  selectIntegration(integration?: WhatsappIntegration) {
    this.selectedIntegration = integration;
  }

  @action
  closeSidebar() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'sidedrawer_close_button',
      place: 'settings_channels_whatsapp',
      section:
        this.selectedSidebarContent === 'set-business-profile'
          ? 'sidedrawer_whatsapp_set_business_profile'
          : 'sidedrawer_whatsapp_connect_number',
    });
    this.isSidebarOpen = false;
  }

  @action
  usePhoneNumber() {
    taskFor(this.submitNumber).perform();
  }

  @action
  connectIntegration(whatsappIntegration: WhatsappIntegration) {
    taskFor(this.startEmbeddedOnboarding).perform(whatsappIntegration);
  }

  @action
  openSidebar() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'navigated',
      object: 'sidedrawer_view',
      place: 'settings_channels_whatsapp',
      section: 'sidedrawer_whatsapp_connect_number',
    });
    this.isSidebarOpen = true;
  }

  @action
  addWhatsAppNumber() {
    this.selectedSidebarContent = 'add-number';
    this.openSidebar();
  }

  @action
  openEmbeddedSignup() {
    taskFor(this.startEmbeddedSignup).perform();
  }

  @action
  showRemoveModal(whatsappIntegration: WhatsappIntegration) {
    this.removingIntegration = whatsappIntegration;
    this.showModal = true;
  }

  @action
  confirmRemoveAccount() {
    taskFor(this.removeAccount).perform();
    if (
      !this.selectedIntegration ||
      !this.connectedWhatsappIntegrations.includes(this.selectedIntegration)
    ) {
      this.selectIntegration(this.firstConnectedIntegration);
    }
    this.showModal = false;
  }

  @action
  openWhatsappManager(whatsappIntegration: WhatsappIntegration) {
    let url = `https://business.facebook.com/wa/manage/phone-numbers/?waba_id=${whatsappIntegration?.whatsappAccountId}&business_id=${whatsappIntegration?.businessId}`;
    safeWindowOpen(url, '_blank');
  }

  @action
  openBusinessVerification(businessId: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'verification_request_button',
      place: 'settings_channels_whatsapp',
      section: 'sidedrawer_whatsapp_connect_number',
    });
    let url = `https://business.facebook.com/settings/security/business_verification?business_id=${businessId}`;
    safeWindowOpen(url, '_blank');
  }

  @action
  setBusinessProfile(integration: WhatsappIntegration) {
    this.selectIntegration(integration);
    this.selectedSidebarContent = 'set-business-profile';
    this.openSidebar();
  }
  @action
  successSubmitNumber() {
    this.successNotification();
    this.selectedSidebarContent = 'connected-accounts';
    taskFor(this.installIntegration).perform();
    if (
      !this.selectedIntegration ||
      !this.connectedWhatsappIntegrations.includes(this.selectedIntegration)
    ) {
      this.selectIntegration(this.firstConnectedIntegration);
    }
    this.intercomEventService.trackAnalyticsEvent({
      action: 'completed',
      object: 'whatsapp_number_connected_successfully',
      place: 'settings_channels_whatsapp',
      section: 'sidedrawer_whatsapp_connect_number',
    });
  }

  @dropTask
  *startEmbeddedSignup(): TaskGenerator<void> {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'get_started',
      current_tab: 'settings',
      app_package_code: 'whatsapp-fqam',
    });
    try {
      let response = yield whatsappEmbeddedSignup(this.appService.app.id, this.fb);
      let success = yield taskFor(this.setupWhatsapp).perform(response.authResponse.accessToken);
      if (success) {
        this.successSubmitNumber();
      } else {
        this.accountPhoneNumbers = yield taskFor(this.fetchAccountPhoneNumbers).perform({
          accessToken: response.authResponse.accessToken,
          accountsOnly: false,
        });
        this.selectedNumber = '0,0';
        this.showMultiplePhoneNumbers = true;
      }
    } catch (e) {
      this.errorNotification(e);
      this.closeSidebar();
    } finally {
      taskFor(this.setGetStartedCDA).perform();
    }
  }

  @dropTask
  *startEmbeddedOnboarding(whatsappIntegration: WhatsappIntegration): TaskGenerator<void> {
    try {
      yield whatsappEmbeddedSignup(this.appService.app.id, this.fb);
      yield taskFor(this.connectWhatsapp).perform(whatsappIntegration);
      this.successSubmitNumber();
    } catch (e) {
      this.errorNotification(e);
    }
  }

  @dropTask
  *connectWhatsapp(whatsappIntegration: WhatsappIntegration): TaskGenerator<void> {
    let data = {
      app_id: this.appService.app.id,
      whatsapp_account_id: whatsappIntegration.whatsappAccountId,
      phone_number: whatsappIntegration.phoneNumber,
    };
    let response = yield post('/ember/whatsapp/integrations/submit_number', data);
    this.store.pushPayload({ 'whatsapp/integration': response });
  }

  @dropTask
  *setupWhatsapp(accessToken: string): TaskGenerator<boolean> {
    let data = {
      app_id: this.appService.app.id,
      access_token: accessToken,
    };
    let response = yield post('/ember/whatsapp/integrations/initialize', data);
    if ('multiple_phone_numbers' in response) {
      return false;
    }

    this.store.pushPayload({ 'whatsapp/integration': response });
    return true;
  }

  @dropTask
  *submitNumber(): TaskGenerator<void> {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'submit_phone_number',
      object: 'get_started',
      current_tab: 'settings',
      app_package_code: 'whatsapp-fqam',
    });
    let [accountIndex, numberIndex] = this.selectedNumber
      .split(',')
      .map((index: string) => parseInt(index, 10));

    let accountId = this.accountPhoneNumbers[accountIndex].id;
    let phoneNumber =
      this.accountPhoneNumbers[accountIndex].phone_numbers[numberIndex].display_phone_number;

    let data = {
      app_id: this.appService.app.id,
      whatsapp_account_id: accountId,
      phone_number: phoneNumber,
    };
    try {
      this.showMultiplePhoneNumbers = false;
      this.selectedNumber = '0,0';
      let response = yield post('/ember/whatsapp/integrations/submit_number', data);
      this.store.pushPayload({ 'whatsapp/integration': response });
      this.successSubmitNumber();
    } catch (e) {
      this.errorNotification(e);
    }
  }

  @dropTask
  *fetchAccountPhoneNumbers({
    accessToken,
    accountsOnly,
  }: {
    accessToken: string;
    accountsOnly: boolean;
  }): TaskGenerator<any> {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'fetch_phone_numbers',
      object: 'get_started',
      current_tab: 'settings',
      app_package_code: 'whatsapp-fqam',
    });
    let data = {
      app_id: this.appService.app.id,
      access_token: accessToken,
      accounts_only: accountsOnly,
    };
    let response = yield post('/ember/whatsapp/integrations/get_shared_whatsapp_accounts', data);
    return response;
  }

  @dropTask
  *setGetStartedCDA(): TaskGenerator<any> {
    let data = { app_id: this.appService.app.id };
    return yield post('/ember/whatsapp/integrations/set_get_started_cda', data);
  }

  @dropTask
  *installIntegration(): TaskGenerator<void> {
    if (this.isWhatsappInstalled) {
      return;
    }
    let params = {
      admin_id: this.appService.app.currentAdmin.id,
      app_id: this.appService.app.id,
      app_package_code: 'whatsapp-fqam',
    };
    let data = JSON.stringify(params);

    let response = yield post('/ember/appstore/app_packages/install', data);
    if (response.ok) {
      this.isWhatsappInstalled = true;
    }
  }

  @dropTask
  *removeAccount(): TaskGenerator<void> {
    yield this.removingIntegration?.destroyRecord();
    this.removingIntegration = null;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'deleted',
      object: 'whatsapp_integration',
      current_tab: 'settings',
      app_package_code: 'whatsapp-fqam',
    });
  }

  @restartableTask *updateBusinessProfileSettings(): TaskGenerator<void> {
    try {
      yield this.selectedIntegration?.updateBusinessProfileSettings({
        app: this.appService.app,
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'new-settings.channels.whatsapp.set-business-profile-sidebar.notification.success',
        ),
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'updated',
        object: 'save_button',
        place: 'settings_channels_whatsapp',
        section: 'sidedrawer_whatsapp_set_business_profile',
      });
      this.closeSidebar();
    } catch (e) {
      console.error(e);
      this.notificationsService.notifyError(
        this.intl.t(
          'new-settings.channels.whatsapp.set-business-profile-sidebar.notification.error',
        ),
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'update_failed',
        object: 'save_button',
        place: 'settings_channels_whatsapp',
        section: 'sidedrawer_whatsapp_set_business_profile',
      });
    }
  }

  successNotification() {
    this.notificationsService.notifyConfirmation(
      this.intl.t('appstore.settings.whatsapp.connect-whatsapp.notification.success'),
    );
    this.intercomEventService.trackAnalyticsEvent({
      action: 'completed',
      object: 'get_started',
      current_tab: 'settings',
      app_package_code: 'whatsapp-fqam',
    });
  }

  errorNotification(e: any) {
    console.error(e);
    this.notificationsService.notifyResponseError(
      e,
      {
        default: this.intl.t('appstore.settings.whatsapp.connect-whatsapp.notification.error'),
      },
      {
        responseProvidedErrors: [403, 422, 500],
        duration: 10000,
      },
    );
  }

  async initFacebook(fb: any) {
    let initSettings = {
      appId: ENV.APP.whatsapp.appId,
      version: this.app.koala_api_version,
      xfbml: true,
    };
    await fb.FBInit(initSettings);
    // @ts-ignore
    window.FB.init(initSettings);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Whatsapp': typeof Whatsapp;
  }
}
