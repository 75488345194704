/* import __COLOCATED_TEMPLATE__ from './companies.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface Args {
  model: any;
  tab: string;
  changeTab: (tab: string) => void;
}

export default class CompaniesComponent extends Component<Args> {
  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (!args.tab) {
      args.changeTab('attributes');
    }
  }

  get selectedTab() {
    return this.args.tab;
  }

  @action
  setSelectedTab(value: string) {
    this.args.changeTab(value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::Companies': typeof CompaniesComponent;
  }
}
