/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Args {
  selectedTab: string;
}

export default class CompanyHeaderComponent extends Component<Args> {
  @service declare intl: IntlService;
  @service declare modalService: any;
  @service declare appService: any;

  get titleToken() {
    return this.intl.t('new-settings.data.companies.page-title');
  }

  @action
  openAttributeCreationModal(type: string) {
    this.modalService.openModal('settings/modals/attribute-details', {
      attribute: null,
      allowArchival: false,
      showPeopleAndCompanyToggleTab: false,
      typeToCreate: type,
    });
  }

  @action
  showSegmentsArticle() {
    window.Intercom('showArticle', 324); // https://www.intercom.com/help/en/articles/324-how-to-segment-your-contacts
  }

  @action
  showGroupUsersByCompanyArticle() {
    window.Intercom('showArticle', 186); // https://www.intercom.com/help/en/articles/186-group-your-users-by-company
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::Companies::Header': typeof CompanyHeaderComponent;
  }
}
