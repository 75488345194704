/* import __COLOCATED_TEMPLATE__ from './segment-name.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
import Component from '@glimmer/component';
import { action } from '@ember/object';

interface Args {
  row: any;
}

export default class SegmentNameComponent extends Component<Args> {
  @action
  saveSegment(newName: string) {
    let segment = this.args.row.data;
    segment.name = newName;
    segment.save();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::People::SegmentName': typeof SegmentNameComponent;
  }
}
