/* import __COLOCATED_TEMPLATE__ from './facebook.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    model: any;
  };
}

const Facebook = templateOnlyComponent<Signature>();
export default Facebook;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Facebook': typeof Facebook;
  }
}
