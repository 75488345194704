/* import __COLOCATED_TEMPLATE__ from './general.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency-decorators';
import type ApplicationInstance from '@ember/application/instance';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { modifier } from 'ember-modifier';
import ClipboardJS from 'clipboard';
import { captureException } from 'embercom/lib/sentry';
import { SELF_SERVE_CHURN_SURVEY_ID } from 'embercom/lib/billing/surveys';
import { startSurvey } from 'embercom/lib/intercom-widget-helper';
import { put } from 'embercom/lib/ajax';

interface Args {
  model: {
    generalSettings: any;
    messengerSettings: any;
    companyAttribute: any;
  };
}

interface Signature {
  Args: Args;
  Element: never;
}
export default class General extends Component<Signature> {
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intl: any;
  @service declare session: any;
  @service declare modalService: any;
  @service declare router: any;
  @service declare intercomEventService: any;
  @service declare customerService: any;

  @tracked declare model: any;
  @tracked selectedTimezone: any = null;
  @tracked appName: string | null = null;
  @tracked adminNameInput = '';
  @tracked isCompanyEnabled = false;
  @tracked preventUpdateFromMessenger = false;
  @tracked isTestWorkspaceEnabled = false;
  @tracked changedTestAppUse = false;
  clipboard: ClipboardJS | null = null;

  constructor(owner: ApplicationInstance, args: Args) {
    super(owner, args);
    this.setInitialValues();
  }

  copyWorkspaceIdContainer = modifier(
    () => {
      this.onRender();
    },
    { eager: false },
  );

  onRender() {
    let container = document.querySelector('#copy-workspace-id-container');
    let copyAppIdButton = container?.querySelector('.form__input-suffix');

    if (copyAppIdButton) {
      copyAppIdButton.classList.add('cursor-pointer');
      copyAppIdButton.setAttribute('data-clipboard-text', this.app.id);
      copyAppIdButton.setAttribute('data-test-copy-workspace-id-button', '');

      if (!this.clipboard) {
        this.clipboard = new ClipboardJS(copyAppIdButton);
        this.clipboard.on('success', () => {
          this.notificationsService.notifyConfirmation(
            this.intl.t('new-settings.workspace.general.workspace-section.copy-app-id-success'),
          );
        });
        this.clipboard.on('error', (err) => {
          console.error(err);
          this.notificationsService.notifyWarning(
            this.intl.t('new-settings.workspace.general.workspace-section.copy-app-id-error'),
          );
        });
      }
    }
  }

  get app() {
    return this.appService.app;
  }

  get isSaving() {
    return taskFor(this.saveGeneralSettings).isRunning;
  }

  get showPaywallForLanguages() {
    return !this.app.hasMultipleLanguages;
  }

  get addLocaleDropdownItems() {
    return this.notPermittedLocales.map((locale: any) => ({
      value: locale.localeId,
      text: locale.name,
    }));
  }

  get notPermittedLocales() {
    return this.languageSettings.supportedLocales.filter(
      (locale: any) => !this.localeIsPermitted(locale),
    );
  }

  get customer() {
    return this.customerService.customer;
  }

  get currentAdmin() {
    return this.session.teammate;
  }

  get isDeleteButtonDisabled() {
    return this.currentAdmin?.name !== this.adminNameInput;
  }

  get messengerSettings() {
    return this.args.model.messengerSettings;
  }

  get generalSettings() {
    return this.args.model.generalSettings;
  }

  get companyAttribute() {
    return this.args.model.companyAttribute;
  }

  get languageSettings() {
    return this.messengerSettings?.languages;
  }

  get localeDropdownItems() {
    return this.languageSettings.supportedLocales.map((locale: any) => ({
      value: locale.localeId,
      text: locale.name,
    }));
  }

  get permittedLocales() {
    return this.languageSettings.supportedLocales.filter((locale: any) =>
      this.localeIsPermitted(locale),
    );
  }

  get defaultLocaleObject() {
    return this.languageSettings.supportedLocales.find(
      (locale: any) => locale.localeId === this.languageSettings.defaultLocale,
    );
  }

  get permittedLocalesWithoutDefault() {
    return this.permittedLocales.filter(
      (locale: any) => locale.localeId !== this.languageSettings.defaultLocale,
    );
  }

  localeIsPermitted(locale: any) {
    let defaultLocale = this.languageSettings.defaultLocale;
    return locale.isPermitted || locale.localeId === defaultLocale;
  }

  @action
  removeLanguage(localeId: string) {
    let localeToRemove = this.languageSettings.supportedLocales.findBy('localeId', localeId);
    localeToRemove.isPermitted = false;
  }

  @action
  setDefaultLanguage(localeId: string) {
    let permitted = this.permittedLocales;

    if (permitted.findBy('localeId', localeId)) {
      let oldDefaultLocale = this.defaultLocaleObject;
      oldDefaultLocale.isPermitted = true;
    }

    this.languageSettings.defaultLocale = localeId;
    this.defaultLocaleObject.isPermitted = false;
  }

  @action
  permitLanguage(localeId: string) {
    let locale = this.languageSettings.supportedLocales.findBy('localeId', localeId);
    locale.isPermitted = true;
  }

  get timezoneOptions() {
    return (this.generalSettings?.get('timezones') || []).map((timezone: any) => ({
      text: timezone.name,
      value: timezone.name,
    }));
  }

  setInitialValues() {
    if (!this.generalSettings) {
      return;
    }
    this.selectedTimezone = this.generalSettings.get('time_zone_name');
    this.appName = this.generalSettings.get('name');
    this.isCompanyEnabled = this.generalSettings.get('companies_enabled');
    this.preventUpdateFromMessenger = this.companyAttribute?.prevent_update_from_messenger;
    this.isTestWorkspaceEnabled = this.generalSettings.get('uses_test_app');
  }

  @dropTask
  *saveGeneralSettings() {
    try {
      if (
        !this.app.id ||
        !this.selectedTimezone ||
        !this.generalSettings ||
        this.generalSettings.isDestroyed
      ) {
        return;
      }
      this.generalSettings.time_zone_name = this.selectedTimezone;
      this.generalSettings.name = this.appName;
      this.generalSettings.companies_enabled = this.isCompanyEnabled;
      this.generalSettings.uses_test_app = this.isTestWorkspaceEnabled;
      yield this.generalSettings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.general.save-button.success-notification'),
      );
      if (this.changedTestAppUse) {
        document.location.reload();
      }
    } catch (error) {
      let notificationText;
      let responseJSON = error.jqXHR.responseJSON;
      if (responseJSON && responseJSON.errors[0]) {
        notificationText = responseJSON.errors[0];
      } else {
        notificationText = this.intl.t('settings.general.save-button.failure-notification');
      }
      this.notificationsService.notifyError(notificationText);
    }
  }

  @dropTask
  *saveCompanyAttributeSettings() {
    try {
      if (!this.app.id || !this.companyAttribute || this.companyAttribute.isDestroyed) {
        return;
      }
      yield put('/ember/standard_data_attributes/companies', {
        app_id: this.app.id,
        prevent_update_from_messenger: this.preventUpdateFromMessenger,
      });
    } catch (error) {
      let notificationText;
      let responseJSON = error.jqXHR.responseJSON;
      if (responseJSON && responseJSON.errors[0]) {
        notificationText = responseJSON.errors[0];
      } else {
        notificationText = this.intl.t('settings.general.save-button.failure-notification');
      }
      this.notificationsService.notifyError(notificationText);
    }
  }

  @dropTask
  *saveMessengerSettings() {
    try {
      if (!this.app.id) {
        return;
      }
      yield this.messengerSettings.save();
    } catch (error) {
      let notificationText;
      let responseJSON = error.jqXHR.responseJSON;
      if (responseJSON && responseJSON.errors[0]) {
        notificationText = responseJSON.errors[0];
      } else {
        notificationText = this.intl.t('settings.general.save-button.failure-notification');
      }
      this.notificationsService.notifyError(notificationText);
    }
  }

  @dropTask
  *deleteApp() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'delete_app_button',
      context: 'from_new_settings',
    });

    if (
      this.customer.subscriptionCancelledAt ||
      this.customer.subscriptionWillBeCancelled ||
      this.customer.hasNoActiveSubscription ||
      this.app.isDeveloperWorkspace
    ) {
      yield this.modalService.openModal('settings/modals/delete-app-modal', this.app);
    } else if (this.app.canSeeSelfServeChurnSurvey) {
      // The last step of the new self serve churn Survey will
      // redirect customers to their cancellation page.
      startSurvey(SELF_SERVE_CHURN_SURVEY_ID);
    } else {
      this.router.transitionTo('apps.app.billing.cancel'); // Fallback for AU / EU workspaces
    }
  }

  @action
  save() {
    taskFor(this.saveGeneralSettings).perform();
    taskFor(this.saveMessengerSettings).perform();
    taskFor(this.saveCompanyAttributeSettings).perform();
  }

  @action
  delete() {
    taskFor(this.deleteApp).perform();
  }

  @action
  toggler(toggleType: string) {
    switch (toggleType) {
      case 'company':
        this.isCompanyEnabled = !this.isCompanyEnabled;
        break;
      case 'prevent-update-from-messenger':
        this.preventUpdateFromMessenger = !this.preventUpdateFromMessenger;
        break;
      case 'test-workspace':
        this.changedTestAppUse = true;
        this.isTestWorkspaceEnabled = !this.isTestWorkspaceEnabled;
        break;
      default:
        break;
    }
  }

  willDestroy() {
    super.willDestroy();
    try {
      this.clipboard?.destroy();
    } catch (e) {
      captureException(e, {
        fingerprint: ['clipboard.destroy', 'new-settings', 'workspace.general'],
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::General': typeof General;
  }
}
