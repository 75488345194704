/* import __COLOCATED_TEMPLATE__ from './quick-invite-modal.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { type Router } from '@ember/routing';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type GuideLibraryService from 'embercom/services/guide-library-service';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import { post } from 'embercom/lib/ajax';
import type { Email } from 'embercom/components/multi-email-input';
import type QuoteService from 'embercom/services/quote-service';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import { DEFAULT_REQUEST_PARAMS } from 'embercom/services/quote-service';

interface Signature {
  Args: {
    isWorkEmail: boolean;
    closeModal: () => void;
    isStandalone?: boolean;
  };
  Element: HTMLDivElement;
}

export default class QuickInviteModal extends Component<Signature> {
  @service declare appService: any;
  @service declare onboardingHomeService: any;
  @service declare router: Router;
  @service declare guideLibraryService: GuideLibraryService;
  @service declare permissionsService: $TSFixMe;
  @service declare quoteService: QuoteService;
  @service declare customerService: any;

  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare notificationsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;

  @tracked showModal = false;
  @tracked allEmails: Email[] = [];
  @tracked partialInput = '';

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.loadServices();
    this.intercomEventService.trackAnalyticsEvent({
      object: 'quick_invite_btn',
      action: 'viewed',
      locale: 'quick-invite-modal',
      success: true,
    });
  }

  async loadServices() {
    await this.customerService.ensureDataIsLoaded.perform();
    if (this.customerService.activePlans?.length) {
      await taskFor(this.quoteService.getQuotes).perform([
        {
          ...DEFAULT_REQUEST_PARAMS,
          planIds: [this.customerService.activePlans[0].id],
          source: 'library-home',
        },
      ]);
    }
  }

  get app() {
    return this.appService.app;
  }

  get inTrial() {
    return this.customerService.customer?.inCardlessTrial;
  }

  get title() {
    if (this.args.isStandalone) {
      return this.intl.t(
        'onboarding.home.steps.invite-teammates.quick-invite-modal.standalone-title',
      );
    }
    return this.intl.t('onboarding.home.steps.invite-teammates.quick-invite-modal.title');
  }

  get formattedPrice() {
    if (!this.customerService.activePlanIds.length) {
      return '';
    }
    let plan = this.customerService.activePlanIds[0];
    let quote = this.quoteService.getQuoteById([plan]);
    let price = quote?.fullSeatPrice(plan, Metric.core_seat_count);

    if (!price) {
      return '';
    }

    return this.intl.formatNumber(price, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  }

  @action
  setPartialInput(string: string) {
    this.partialInput = string;
  }

  get sendInviteButtonDisabled() {
    let invalidEmails = this.allEmails.filter((email) => !!email.error);
    return (
      (!this.allEmails.length && !this.partialInput) ||
      invalidEmails.length !== 0 ||
      !this.app.invitingPermitted
    );
  }

  get priceString() {
    return this.intl.t(
      'onboarding.home.steps.invite-teammates.quick-invite-modal.permissions-and-pricing-explanation',
      {
        inTrial: this.inTrial,
        price: this.formattedPrice,
        htmlSafe: true,
      },
    );
  }

  @action
  onEmailUpdate(emails: Email[]) {
    this.allEmails = emails;
  }

  @action
  navigateToInvitePage() {
    let validEmails = this.allEmails.filter((email) => !email.error);

    this.router.transitionTo('apps.app.settings.workspace.teammates.invite.new', {
      queryParams: {
        emails:
          validEmails.length > 0 ? validEmails.map((validEmail) => validEmail.value).join(',') : '',
      },
    });
    if (this.args.closeModal) {
      this.args.closeModal();
    }
  }

  @action async performInvite() {
    let success = false;
    try {
      let validEmails = this.allEmails.filter((email) => !email.error);
      await taskFor(this.inviteTeammate).perform();
      this.notificationsService.notifyConfirmation(
        this.intl.t('onboarding.home.steps.invite-teammates.quick-invite-modal.success-banner', {
          numberOfTeammatesInvited: validEmails.length,
        }),
      );
      success = true;
      this.args.closeModal();
    } catch (error) {
      this.notificationsService.notifyError(this.intl.t('onboarding.home.developer-invite.error'));
    }
    this.intercomEventService.trackAnalyticsEvent({
      object: 'quick_invite_btn',
      action: 'clicked',
      locale: 'quick-invite-modal',
      success,
    });
    if (this.guideLibraryService.canUseGuideLibraryService) {
      await this.guideLibraryService.markStepCompleted(
        'guide_library_foundational_steps_invite_teammates',
      );
    }
  }

  @task({ drop: true }) *inviteTeammate() {
    let permissions = this.createPermissionsObject();

    let invitees = this.allEmails
      .filter((email) => !email.error)
      .map((email) => {
        return {
          email: email.value,
          app_id: this.app.id,
          seat_types: ['core'],
          ...permissions,
        };
      });

    if (invitees.length === 0) {
      return;
    }

    yield post('/ember/invite_admin_groups', {
      invitees,
      app_id: this.appService.app.id,
    });
  }

  private createPermissionsObject() {
    let permissionsObject = this.store.createRecord('permission', {
      app_id: this.app.id,
    });
    permissionsObject.setAllPermissions(true);
    return permissionsObject.serialize();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::QuickInviteModal': typeof QuickInviteModal;
  }
}
