/* import __COLOCATED_TEMPLATE__ from './popular-templates.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { WORKFLOWS_SETUP_POPULAR_TEMPLATE_IDS } from 'embercom/lib/operator/custom-bots/constants-2';
import type Template from 'embercom/models/template';

interface Args {
  allTemplates: Template[];
  templateGroups: $TSFixMe;
  shouldShowPaywallBadges: boolean;
  recordAnalyticsEvent: (event: string) => void;
  selectTemplate: (template: Template, isNew: boolean) => void;
  transitionToPreview: () => void;
  createWorkflow: (
    contentType: string | null,
    _deliveryChannel: null,
    _audienceType: null,
    templateId: string | null,
  ) => void;
}

export default class PopularTemplates extends Component<Args> {
  get templates() {
    let filteredTemplates = this.args.allTemplates.filter((template) =>
      WORKFLOWS_SETUP_POPULAR_TEMPLATE_IDS.includes(template.id),
    );
    return filteredTemplates.sort(
      (a, b) =>
        WORKFLOWS_SETUP_POPULAR_TEMPLATE_IDS.indexOf(a.id) -
        WORKFLOWS_SETUP_POPULAR_TEMPLATE_IDS.indexOf(b.id),
    );
  }

  @action
  showPreview(template: Template) {
    this.args.selectTemplate(template, false);
    this.args.transitionToPreview();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export interface Registry {
    'Outbound::NewContentModal::Workflows::PopularTemplates': typeof PopularTemplates;
  }
}
